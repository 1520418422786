import React,{useState,useEffect} from 'react'
import Header from "../../../HeaderNavbar";
import Footer from "../../../Footer";
import { useLocation, useNavigate } from 'react-router-dom';
import Subload from "../../../../../Assets/subject_loading.gif"
import KInput from "../../../common/KInput";
import KDropdown from "../../../common/KDropdown";
import KHeader from "../../../common/KHeader";
import KButton from "../../../common/KButton";
import useResponsive from "../../../../../hooks/useResponsive/useResponsive";

const AddStaff = ({showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm
}) => {

  const { isBigScreen } = useResponsive();
  const gender = [
    {value: "male", label: "Male"},
    {value: "female", label: "Female"}
  ]
  const blgroup = [
    {value: "a1+", label: "A1+"},
    {value: "ab+", label: "AB+"},
    {value: "ab-", label: "Ab-"},
    {value: "o+", label: "O+"},
    {value: "b+", label: "B+"}
  ]



  return (
    <div
    onClick = {()=>disableOnOuterTouch()}
    >
        <div className="Navbar">
        <Header 
        showProfile={showProfile}
    
        falseShowProfile={falseShowProfile}
        notofShowProfile={notofShowProfile}
        ShowNotifications={ShowNotifications}
        showNotification={showNotification}
        falseShowNotification={falseShowNotification}

      />
        </div>
        <div className={"Dash_home_QWI"}>
     
        <div className={"Subject_container"}
        style={{
            height: window.innerHeight - 60 + "px",
            overflowY: "auto"
        }}
        >
       {/* Staff Add */}
       {/* Write Code */}

       <KHeader title="Staff Information " containerClassName={`marginTop-20`} />

       <div>
          <div className="container-fluid px-5 py-3">
            <div className="d-flex flex-row ">
              <div className=" d-flex flex-row col-sm-8 p-0" >
              <KInput
                label="First Name"
                isRequired={true}
                placeholder="Enter First Name"
                width={"300px"}
                containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                type="text"
              />

              <KInput
                label="Last Name"
                isRequired={true}
                placeholder="Enter Last Name"
                width={"300px"}
                containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                type="text"
              />
              </div>          
            </div>

            <div className="d-flex flex-column flex-md-row mt-4">
              <KDropdown
                containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                label="Select Gender"
                isRequired={true}
                placeholder="Select Gender"
                options={gender}                
              />

              <KInput
                label="Date of Birth"
                isRequired={true}
                // placeholder="Enter Last Name"
                width={"300px"}
                containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                type="Date"
              />   

              <KDropdown
                containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                label="Select Age"
                isRequired={true}
                placeholder="Select Age"
                // options={ }                
              />     
 
            </div>

            <div className=" d-flex flex-row col-sm-8 p-0 mt-4" >
              <KDropdown
                containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                label="Blood Group"
                isRequired={true}
                placeholder="Select Blood Group"
                options={blgroup}                
              />

              {/* <KInput
                  label="Adhaar Number"
                  isRequired={true}
                  placeholder="Enter Adhaar Number"
                  width={"300px"}
                  containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                  type="number"
                /> */}

              <KInput
                  label="Mobile Number"
                  isRequired={true}
                  placeholder="Enter Mobile Number"
                  width={"300px"}
                  containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                  type="number"
                />
            </div>

            <div className=" d-flex flex-row col-sm-4 mt-4 p-0" >
              <KInput
                label="Qualification"
                isRequired={true}
                placeholder="Enter Qualification"
                width={"300px"}
                containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                type="text"
              />

              {/* <KInput
                label="Father Mobile Number"
                isRequired={true}
                placeholder="Enter Mobile Number"
                width={"300px"}
                containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                type="number"
              /> */}
              </div>
            </div>


            {/* <KHeader title="Communication Details" containerClassName={`marginTop-20`} /> */}

          <div className="container-fluid px-5 py-3">          

            {/* <div className=" d-flex flex-row col-sm-8 mt-1 p-0" >
              <KInput
                label="Address Line 1"
                isRequired={true}
                placeholder="Enter Address Line 1"
                width={"300px"}
                containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                type="text"
              />

              <KInput
                label="Address Line 2"
                isRequired={true}
                placeholder="Enter Address Line 2"
                width={"300px"}
                containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                type="text"
              />
              </div>

            <div className="d-flex flex-column flex-md-row mt-4">
              <KInput
                  label="Area"
                  isRequired={true}
                  placeholder="Enter Area"
                  width={"300px"}
                  containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                  type="text"
                />

              <KInput
                  label="City"
                  isRequired={true}
                  placeholder="Enter City"
                  width={"300px"}
                  containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                  type="text"
                />        

              <KInput
                  label="State"
                  isRequired={true}
                  placeholder="Enter State"
                  width={"300px"}
                  containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                  type="text"
                />
            </div>

            <div className=" d-flex flex-row col-sm-8 p-0 mt-4" >
              <KInput
                  label="Country"
                  isRequired={true}
                  placeholder="Enter Country"
                  width={"300px"}
                  containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                  type="text"
                /> 

              <KInput
                  label="Pincode"
                  isRequired={true}
                  placeholder="Enter Pincode"
                  width={"300px"}
                  containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                  type="number"
                /> 
            </div> */}

            <div
                className="d-flex flex-column justify-content-end flex-md-row pr-5 mt-4 mb-4">
                <KButton
                  value="Save"
                  btnColor={"green"}
                  className={`px-3 py-1 ButtonSize  ${
                    isBigScreen ? "ml-3" : "mt-2 ml-4"
                  }`}
                />
                <KButton
                  value="Cancel"
                  className={`px-3 py-1 ButtonSize ${
                    isBigScreen ? "ml-3" : "mt-2 ml-4"
                  }`}
                  btnColor={"red"}
                />
            </div>
          </div>
        </div>
      </div>

        <Footer 
          // openForm={openForm}
          // shoq={shoq}
          // closeForm={closeForm}
          // KpostIframe={KpostIframe}
        />
        </div>
          
      
    </div>
  )
};

export default AddStaff