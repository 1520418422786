import React, { useState, useRef,useContext } from "react";
import FormButton from './FormButton'
import FormInput from './FormInput'
import {KampusLogin,loginUser} from "../../../services/Apiservices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Warning from "../Toastify/Warning";
import Success from "../Toastify/Success";
import Danger from "../Toastify/Danger";
import { FiEyeOff,FiEye } from "react-icons/fi"
import { FaUser,FaLock } from "react-icons/fa"
import {Authcontext} from "../Context/Context"

function Form() {

  
//  const {state, dispatch} = React.useContext(Authcontext)
  const [passwordType, setPasswordType] = useState("password");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ userName: "", password: "" });
  const passwordRef = useRef();

  // const [loginCredential,setLoginCredential] = useState({
  //   userLoginID : '',
  //   password : '',
  //   showPassword : false
  // })


  const navigate = useNavigate();

  const togglePassword =(value)=>{
    if(passwordType==="password")
    {
     setPasswordType(value)
     return;
    }
    setPasswordType(value)
  }

  const submitkampusLogin = async() => {

    if (userName === "") {
      toast.warning(<Warning body={"Enter your user name"} />);
      setErrors({ ...errors, userName: "Enter your user name" });
    } else if (password === "") {
      toast.warning(<Warning body={"Enter your password"} />);
      setErrors({ ...errors, password: "Enter your Password" });
    } else if (userName && password) {
          let res;
          try {
            res = await loginUser({
              userName: userName,
              password: password,
            });

            if (res.success === true) {
              try {
              console.log("success","REACT NATIVE RESPONSE LOGIN")
              window.ReactNativeWebView.postMessage(JSON.stringify({ "type": "login","domain": "iskcon","login" : true}));
              document.ReactNativeWebView.postMessage(JSON.stringify({ "type": "login","domain": "iskcon","login" : true}));
              } catch (e){
                console.log(e,"REACT NATIVE RESPONSE LOGIN")
              }
              toast.success(<Success body={res.message} />);
              localStorage.setItem("userCreds", JSON.stringify(res));
              // dispatch({type:"LOGIN",payload :res.value})
              if(res.user.userTypeId.userType === 'ADMIN'){
                navigate("/dashboard");
              }else {
                navigate("/exam/student");
              }
              
            } else  if (res.success === false) {
              toast.error(<Danger body={res.error} />);
            } else {
              toast.error(<Danger body={"Please check your username and password!."} />);
            }
          } catch (e) {
            toast.error(<Danger body={"Please check your username and password!."} />);
            console.log(e);
          }
    }

  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitkampusLogin();
      }}
      >
    <div>
       <FormInput
       value={userName}
       func={setUserName}
       reference={passwordRef}
       textIcon={FaUser}
       description="Username" placeholder="Enter your username" 
       type="text" />
     <FormInput
     value={password}
     func={setPassword}
     reference={passwordRef}
     textIcon={FaLock}
     description="Password" placeholder="Enter your password" type={passwordType} />
   
      <span className="pswd_toggle" 
      onMouseEnter={()=>togglePassword("text")}
            onMouseLeave={()=>togglePassword("password")}
      // onClick={togglePassword}
      >
      { passwordType==="password"? <FiEyeOff /> :<FiEye /> }
      </span>
                    
     <FormButton
     title="Log in"/>
    </div>
    </form>
  )
}

export default Form