import React from 'react'
import { CCol,CModal,CModalBody } from "@coreui/react";
import Header from "./HeaderNavbar";

const Ksmacc = ({showProfile,falseShowProfile,notofShowProfile,ShowNotifications,showNotification,falseShowNotification,disableOnOuterTouch}) => {

  return (
    <div
    onClick = {()=>disableOnOuterTouch()}
    >
        <div className="Navbar">
        <Header 
        showProfile={showProfile}
        // profileIcon={KpIcon}
        // username={state.adminName ? state.adminName : state.user}
        falseShowProfile={falseShowProfile}
        notofShowProfile={notofShowProfile}
        // institutionName={state.institutionName && String(state.institutionName).toUpperCase()}
        ShowNotifications={ShowNotifications}
        showNotification={showNotification}
        falseShowNotification={falseShowNotification}

      />
        </div>
        <CCol md="12" style={{ height: window.innerHeight - 60 +"px", marginTop: "54px"}}>
            <iframe
                    title={`https://edu.indiakpost.com/login?username=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName}&password=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).password}`}
                    className={"w-100 h-100"}
                    src={`https://edu.indiakpost.com/login?username=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName}&password=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).password}`}
                />
        </CCol>

    </div>
  )
}

export default Ksmacc