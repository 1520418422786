import { CCard } from "@coreui/react";
import React from "react";
// import { FiBookOpen } from "react-icons/fi";
import StLogo from "../../Assets/Img.png";

const KatbookBooks = (props) => {
  // const userCreds = state.creds;
  const { x } = props;
  console.log(x,"KATBOOKX");
  // console.log(props.count,"KATBOOKX");
  // console.log(count,"KATBOOKX");

  // console.log(x.Books.path.split('"parentVaribaleId"')[0],"WWWWWWWWWWWWWWWWWWWWWWWW");

  console.log(props.usercreds.user,"COVER IMAGE");

  return (
    <>
      <div key={1} className={"Margin_Left_Card marginBottom-20"}>
        <CCard className={"Card_Book_List"} style={{ marginTop: x.Books.coverImage !== undefined ? "" : "30px"}}>
        { x.Books.coverImage !== undefined &&
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ width: "45%" }}>
              <img
                onClick={() => {
                  window.open(
                    `https://iskconsamskriti.katbook.com/#/kampus/BookView/${x.Books.path.split('"parentVaribaleId"')[0]}"parentVaribaleId":{"username":"${props.usercreds.user.userName}","code":"${props.usercreds.user.password}"}}/1/${x.Books.bookId}/1/1`
                  );
                //   window.close();
                }}
                src={
                  x.Books.coverImage
                    // .replace(/\//, "//")
                    .replaceAll("192.168.2.202:2504", "api1.katbook.com")
                    .replaceAll("192.168.2.207:2504", "api1.katbook.com")
                    .replaceAll(
                      "http://api1.katbook.com",
                      "https://api1.katbook.com"
                    )
                    .replaceAll(
                      "http:/api1.katbook.com",
                      "https://api1.katbook.com"
                    )
                  // .replaceAll('192.168.2.202:2504', '192.168.2.202:2504').replaceAll('192.168.2.207:2504', '192.168.2.202:2504').replaceAll("http://api1.katbook.com", "https://api1.katbook.com").replaceAll('http:/api1.katbook.com',"https://api1.katbook.com")
                }
                className={"Image_Size"}
                alt=""
              />
            </div>
          </div>
        }

          <div className="Pad_Mar">
            <div 
            style={{
              cursor: "pointer"
            }}
            onClick={() => {
                  window.open(
                    `https://iskconsamskriti.katbook.com/#/kampus/BookView/${x.Books.path.split('"parentVaribaleId"')[0]}"parentVaribaleId":{"username":"${props.usercreds.user.userName}","code":"${props.usercreds.user.password}"}}/1/${x.Books.bookId}/1/1`
                  );
                //   window.close();
                }}
                >
              <h5 className={"Booklist_Subject"}>
              {/* {x.Books.book.toString().length > 25 ? x.Books.book.toString().slice(0,30)+'...' : x.Books.book} */}
              {x.Books.book}
              </h5>
              
            </div>
            { props.usercreds.userType === 'Student' &&
            <div style={{ marginTop: "5px" }}>
              <img src={StLogo} width="22" height="22" alt="" /> &nbsp;
              <span className="Manoj_span">
                {x.teacherName}
              </span>
            </div>
            }
            {/* <div className={"w-100"}>
            <div className={"myProgress marginTop-10"} >
            <div className={props.count === 0 ? "myBar" : props.count === 1 ? "grennmyBar" : props.count === 2 ? "orangemyBar" : props.count === 3 ? "purplemyBar" : props.count === 4 ? "pinkmyBar" : "myBar"} style={{width: props.count === 0 ? "50%" : props.count === 1 ? "90%" : props.count === 2 ? "65%" : props.count === 3 ? "30%" : props.count === 4 ? "88%" : "40%"}}></div>
            </div>
            <div className={"myprogress_pertg"}>
              <span className={"Percent_Font"}>
                {props.count === 0 ? "50%" : props.count === 1 ? "90%" : props.count === 2 ? "65%" : props.count === 3 ? "30%" : props.count === 4 ? "88%" : "40%"}
              </span>
            </div>
            </div> */}


            {/* <div className={"Book_U_PC"}>
              <div className={"Units_Count_Container"}>
                <FiBookOpen className={"BookOpen_Icon"} /> &nbsp;
                <span>5/{x.unit ? x.unit : 10} </span>
                <span className={"Units_Count_Text"}>Units</span>
              </div>
              <div className={"Portions_covered_Text_Container"}>
                <p>Portions covered</p>
              </div>
            </div> */}


            {/* <div className="bookopenjai">
                  <div className="bokopenjai">
                    <div>                     
                    <FiBookOpen className={"BookOpen_Icon"} /> &nbsp;
                      <span className={"Units_Font"} >5/{x.unit ? x.unit : 10} Units</span>
                    </div>
                    <div className="bookcoverd"> 
                      <span className={"Portions_covered"} style={{width:"max-content"}}>
                        Portions covered</span>
                    </div>
                  </div>

                  <div className="bookclarify1jai"></div>

                  <div className="bookclarify2jai">
                    <div className="d-flex pl-1 align-items-center gap-1">
                      <span className="green-bullet-sm">&#8226;</span>
                      <span className="color-66747F Clarify_Font"
                        style={{
                          width:'fit-content',
                          height:'fit-content',
                          marginTop: "0px",
                        }}
                      > Clarified (12)</span>
                    </div>

                  <div className="d-flex pl-1 align-items-center gap-1">
                  <span className="orange-bullet-sm">&#8226;</span>
                  <span
                  className="color-66747F Y_T_Clarify"
                  style={{
                    width:'max-content',
                    height:'fit-content',
                    marginTop: "0px",
                  }}> Yet to Clarify (12)</span>
                    </div>
                  </div>
            </div> */}


          </div>
        </CCard>
      </div>
    </>
  );
};

export default KatbookBooks;
