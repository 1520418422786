import React from 'react'
import Event_card from './Event_card';
import { CModal, CModalBody  } from '@coreui/react'

function Event() {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const current = new Date();
    const date = ` ${current.getDate()} ${months[current.getMonth()]}`;
    const date1 = ` ${current.getDate()+1} ${months[current.getMonth()]}`;

    const ModelArray= [
        {time: "08:00 AM - 08:45 AM, 10 Feb 2022", title: "School Chess Competition", Content: "International Chess Day is celebrated on July 20 to celebrate one of the oldest and most popular games in history.",bordercolor:"#06C270",cardcolor:"#A8EACD",color:"#3377FF",lesson:"#66747F",},
    ]

  const TodayArray =[
    {time: "08:00 AM - 08:45 AM, 10 Feb 2022", title: "School Sports day", Content: "Sports day is an occasion where pupils, teachers, and parents come together for a day of competitive sport. Some schools choose to offer medals or prizes to the winners of the various events, but for others, participation is its own reward.",bordercolor:"#3377FF",cardcolor:"#E3EDFF",color:"#3377FF",lesson:"#66747F",},
    {time: "08:00 AM - 08:45 AM, 10 Feb 2022", title: "School Chess Competition", Content: "International Chess Day is celebrated on July 20 to celebrate one of the oldest and most popular games in history.",bordercolor:"#06C270",cardcolor:"#A8EACD",color:"#3377FF",lesson:"#66747F",},
  ]
  const TommorowArray =[
    {time: "08:00 AM - 08:45 AM, 10 Feb 2022", title: "School NCC Day", Content: "NCC day is celebrated on the 4th Sunday in November every year. The Government of India started it to create awareness among students about their duties towards the country and raise awareness about our heritage and tradition.",bordercolor:"#FC5F37",cardcolor:"#FEC7B9",color:"#3377FF",lesson:"#66747F",},
    {time: "08:00 AM - 08:45 AM, 10 Feb 2022", title: "School Library Day", Content: "National Librarians' Day is observed on 12th August every year to commemorate the birth anniversary of Padmashri Dr S. R. Ranganathan (1892-1972), who is considered as father of library science in India. ",bordercolor:"#9B36EA",cardcolor:"#DCB9F8",color:"#3377FF",lesson:"#66747F",},
    {time: "08:00 AM - 08:45 AM, 10 Feb 2022", title: "School Speech competition", Content: "In some British schools, speech day is a day, usually at the end of the school year, when prizes are presented to pupils and speeches are made by guest speakers and the head teacher.",bordercolor:"#757575",cardcolor:"#EEEEEE",color:"#3377FF",lesson:"#66747F",},
  ]

  const [visible, setVisible] = React.useState(false)

  return (
    <div>   
        <div className="WEBSHOW">
           <div className={"eventcardDD"} onClick={()=>{setVisible(!visible)}}>
          <div className={"eventcard"}>
          <div className='d-flex px-3 titleevent'>
                    Today, {date}
                </div>  
            {
                TodayArray.map( (item, i) =>
                <div className='d-flex flex-column w-100'>
            <div style={{ height: "5px" }}></div>

            <div className={"English_Time2"}style={{
            borderLeftColor : item.bordercolor,
            backgroundColor:item.cardcolor}}>
            <div >
                <span className={"Eng"} style={{color:item.color}}>
                                {item.time}                    
                </span>
            </div>
                    <div className='D_Flex_Unit'>
                        <div className='Tilelelele'>
                            {item.title}
                        </div>
                    </div>
            <div  className={"D_FLex_Unit"}>
                <div>
                    <p className={"Unit_supplementartyQ"} style={{color:item.lesson,
                        whiteSpace: "nowrap",
                        width: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}>
                        {item.Content}                                    
                    </p>
                </div>                
            </div>
        </div>
    </div>
                )
            }           


    <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
      {
                ModelArray.map( (item, i) =>
                <div className='d-flex flex-column w-100'>
            <div style={{ height: "5px" }}></div>
            <div className={"English_Time2"}style={{
            borderLeftColor : item.bordercolor,
            backgroundColor:item.cardcolor}}>
            <div >
                <span className={"Eng"} style={{color:item.color}}>
                                {item.time}                    
                </span>
            </div>
                    <div className='D_Flex_Unit'>
                        <div className='Tilelelelesd'>
                            {item.title}
                        </div>
                    </div>
            <div  className={"D_FLex_Unit"}>
                <div>
                    <p className={"Unit_supplementartyMod"} style={{color:item.lesson, 
                        }}>
                        {item.Content}                                    
                    </p>
                </div>                
            </div>
        </div>
    </div>
                )
            }          
    </CModal>

<div className='d-flex px-3 mt-2 titleevent'>
                    Tommorow, {date1}
                </div>
            {
                TommorowArray.map( (item, i) =>
                <div className='d-flex flex-column w-100'>
            <div style={{ height: "5px" }}></div>
            <div className={"English_Time2"}style={{
            borderLeftColor : item.bordercolor,
            backgroundColor:item.cardcolor}}>
            <div >
                <span className={"Eng"} style={{color:item.color}}>
                                {item.time}                    
                </span>
            </div>
                    <div className='D_Flex_Unit'>
                        <div className='Tilelelele'>
                            {item.title}
                        </div>
                    </div>
            <div  className={"D_FLex_Unit"}>
                <div>
                    <p className={"Unit_supplementartyQ"} style={{color:item.lesson,
                    whiteSpace: "nowrap",
                    width: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",}}>
                        {item.Content}                                    
                    </p>
                </div>                
            </div>
        </div>
    </div>
                )
            }           
    </div>
    </div>
    </div>

<div className="MbileShow">
    <div className='d-flex px-3 titleevent'>
                    Today, {date}
                </div>  

    <div className={"eventcardDD2"} onClick={()=>{setVisible(!visible)}}>
          <div className={"eventcard"}>
          
            {
                TodayArray.map( (item, i) =>
                <div className='d-flex flex-column w-100'>
            <div style={{ height: "5px" }}></div>

            <div className={"English_Time2"}style={{
            borderLeftColor : item.bordercolor,
            backgroundColor:item.cardcolor}}>
            <div >
                <span className={"Eng"} style={{color:item.color}}>
                                {item.time}                    
                </span>
            </div>
                    <div className='D_Flex_Unit'>
                        <div className='Tilelelele'>
                            {item.title}
                        </div>
                    </div>
            <div  className={"D_FLex_Unit"}>
                <div>
                    <p className={"Unit_supplementarty"} style={{color:item.lesson}}>
                        {item.Content}                                    
                    </p>
                </div>                
            </div>
        </div>
    </div>
                )
            }         
    </div>
    </div>



    <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
      {
                ModelArray.map( (item, i) =>
                <div className='d-flex flex-column w-100'>
            <div style={{ height: "5px" }}></div>
            <div className={"English_Time2"}style={{
            borderLeftColor : item.bordercolor,
            backgroundColor:item.cardcolor}}>
            <div >
                <span className={"Eng"} style={{color:item.color}}>
                                {item.time}                    
                </span>
            </div>
                    <div className='D_Flex_Unit'>
                        <div className='Tilelelelesd'>
                            {item.title}
                        </div>
                    </div>
            <div  className={"D_FLex_Unit"}>
                <div>
                    <p className={"Unit_supplementartyMod"} style={{color:item.lesson}}>
                        {item.Content}                                    
                    </p>
                </div>                
            </div>
        </div>
    </div>
                )
            }          
    </CModal>

<div className='d-flex px-3 mt-2 titleevent'>
                    Tommorow, {date1}
                </div>

                <div className={"eventcardDD"} onClick={()=>{setVisible(!visible)}}>
          <div className={"eventcard"}>
            {
                TommorowArray.map( (item, i) =>
                <div className='d-flex flex-column w-100'>
            <div style={{ height: "5px" }}></div>
            <div className={"English_Time2"}style={{
            borderLeftColor : item.bordercolor,
            backgroundColor:item.cardcolor}}>
            <div >
                <span className={"Eng"} style={{color:item.color}}>
                                {item.time}                    
                </span>
            </div>
                    <div className='D_Flex_Unit'>
                        <div className='Tilelelele'>
                            {item.title}
                        </div>
                    </div>
            <div  className={"D_FLex_Unit"}>
                <div>
                    <p className={"Unit_supplementarty"} style={{color:item.lesson}}>
                        {item.Content}                                    
                    </p>
                </div>                
            </div>
        </div>
    </div>
                )
            }           
    </div>
    </div>
          </div>
          </div>

  )
}

export default Event