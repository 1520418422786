import React from "react";
import Form from "./Form";
import FormHeader from "./FormHeader";
import LOGOImg from "../../../Assets/iskcon.png";

function LoginForm() {
  return (
    <div
      className={"kampus_Main_Card"}
      style={{
        height: window.innerHeight + "px",
      }}
    >
      <img
        src={LOGOImg}
        className={"Login_logoImg"}
        style={{ marginBottom: "20px" }}
      />
      <div id="loginform">
        <FormHeader title="Sri Krishna Contest Login" />
        <Form />
      </div>
    </div>
  );
}
export default LoginForm;
