import React from "react";
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import { useMediaQuery } from "react-responsive";

const Attendance = ({
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
}) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const weeks = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  return (
    <div onClick={() => disableOnOuterTouch()}>
      <div className="Navbar">
        <Header
          showProfile={showProfile}
          // profileIcon={KpIcon}
          // username={state.adminName ? state.adminName : state.user}
          falseShowProfile={falseShowProfile}
          notofShowProfile={notofShowProfile}
          // institutionName={state.institutionName && String(state.institutionName).toUpperCase()}
          ShowNotifications={ShowNotifications}
          showNotification={showNotification}
          falseShowNotification={falseShowNotification}
        />
      </div>
      {isDesktopOrLaptop && (
        <div className={"Dash_home_QWI"}>
          <div
            className="myattendcard2w"
            style={{
              height: window.innerHeight - 117 + "px",
              overflowY: "auto",
            }}
          >
            <div className="d-flex flex-row justify-content-between">
              {/* child1 */}
              <div className="d-flex flex-row marginTop-20 marginLeft-20">
                {/* child1.1 */}
                <h5 className={"Time_Table"}>My Attendance</h5>

                {/* child1.3 end*/}
              </div>
              {/* child 1 end */}

              {/* Child 2 */}
              <div className="d-flex flex-row px-3 marginTop-20 marginRight-20">
                {/* child 2.1 */}
                <div>
                  <span className="green-bullet-sm">&#8226;</span>
                </div>
                {/* child 2.2 */}
                <div className="dateaten">
                  {new Date().toLocaleDateString("en-us", {
                    weekday: "short",
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </div>
              </div>
              {/* child 2 end */}
            </div>

            <div className="borderboxAtten">
              <div className="d-flex flex-row marginTop-20">
                <div className="d-flex flex-column px-5">
                  {/* child1.1.1 */}
                  <div className="attenpara">Present</div>
                  {/* child 1.1.2 */}
                  <div className="attennumber">153</div>
                </div>
                {/* child1.1 end*/}

                {/* Child1.2 */}
                <div className="d-flex flex-column px-5">
                  {/* child 1.2.1 */}
                  <div className="attenpara">Absent</div>
                  {/* child 1.2.2 */}
                  <div className="attennumber">17</div>
                </div>
                {/* child1.2 end*/}

                {/* child 1.3 */}
                <div className="d-flex flex-column ">
                  {/* child 1.3.1 */}
                  <div className="attenpara attenpadapada">
                    Total Working days
                  </div>
                  {/* child 1.3.2 */}
                  <div className="attennumber px-5">170</div>
                </div>
              </div>

              <div className="d-flex flex-row justify-content-evenly align-items-end mt-5">
                <div className="barboriee1"></div>
                <div className="barboriee2"></div>
                <div className="barboriee8"></div>
                <div className="barboriee4"></div>
                <div className="barboriee5"></div>
                <div className="barboriee6"></div>
                <div className="barboriee7"></div>
                <div className="barboriee3"></div>
                <div className="barboriee9"></div>
                <div className="barboriee10"></div>
                <div className="barboriee11"></div>
                <div className="barboriee11"></div>
              </div>

              <div className="d-flex flex-row justify-content-evenly mt-2">
                <div className="janatten">Jan</div>
                <div className="febatten">Feb</div>
                <div className="maratten">Mar</div>
                <div className="apratten">Apr</div>
                <div className="mayatten">May</div>
                <div className="junatten">Jun</div>
                <div className="julatten">Jul</div>
                <div className="augnatten">Aug</div>
                <div className="sepatten">Sep</div>
                <div className="octatten">Oct</div>
                <div className="novatten">Nov</div>
                <div className="decatten">Dec</div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isTabletOrMobile && (
        <div className={"Dash_home_QWI"}>
          <h5 className={"MyAttenMobi"}>My Attendance</h5>
          <div className="myattendcard2w"></div>
          <div className="d-flex flex-column px-3 upheight tabletv_Qi">
            <div>
              <h5 className={"Time_Table"}>My Attendance</h5>{" "}
            </div>

            <div className="d-flex flex-row">
              <div>
                <span className="green-bullet-sm">&#8226;</span>
              </div>
              <div className="dateaten">
                {new Date().toLocaleDateString("en-us", {
                  weekday: "short",
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })}
              </div>
            </div>
          </div>

          <div className="borderbottmobi">
            <div className="d-flex flex-row marginTop-20 justify-content-evenly ">
              <div className="d-flex flex-column align-items-center">
                <div className="attenparamobi">Present</div>
                <div className="attennumbermobi">153</div>
              </div>
              <div className="d-flex flex-column align-items-center">
                <div className="attenparamobi">Absent</div>
                <div className="attennumbermobi">17</div>
              </div>
              <div className="d-flex flex-column align-items-center">
                <div className="attenparamobi attenpadapada">
                  Total Working days
                </div>
                <div className="attennumbermobi">170</div>
              </div>
            </div>

            <div className="p-3">
              <div className="d-flex flex-row justify-content-around align-items-end">
                <div className="PROGRESS">
                  <div className="barboriee1"></div>
                  <div className="janatten">Jan</div>
                </div>
                <div className="PROGRESS">
                  <div className="barboriee2"></div>
                  <div className="janatten">Feb</div>
                </div>
                <div className="PROGRESS">
                  <div className="barboriee8"></div>
                  <div className="janatten">Mar</div>
                </div>
                <div className="PROGRESS">
                  <div className="barboriee4"></div>
                  <div className="janatten">Apr</div>
                </div>
                <div className="PROGRESS">
                  <div className="barboriee5"></div>
                  <div className="janatten">May</div>
                </div>
                <div className="PROGRESS">
                  <div className="barboriee6"></div>
                  <div className="janatten">Jun</div>
                </div>
                <div className="PROGRESS">
                  <div className="barboriee7"></div>
                  <div className="janatten">Jul</div>
                </div>
                <div className="PROGRESS">
                  <div className="barboriee3"></div>
                  <div className="janatten">Aug</div>
                </div>
                <div className="PROGRESS">
                  <div className="barboriee9"></div>
                  <div className="janatten">Sep</div>
                </div>
                <div className="PROGRESS">
                  <div className="barboriee10"></div>
                  <div className="janatten">Oct</div>
                </div>
                <div className="PROGRESS">
                  <div className="barboriee11"></div>
                  <div className="janatten">Nov</div>
                </div>
                <div className="PROGRESS">
                  <div className="barboriee12"></div>
                  <div className="janatten">Dec</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer
        openForm={openForm}
        shoq={shoq}
        closeForm={closeForm}
        KpostIframe={KpostIframe}
      />
    </div>
  );
};

export default Attendance;
