import react from 'react';
import Navbar from './Navbar';

const SubHeader = ({userData}) => {
    return (
      <header >
        <div className="nav-area">
          <Navbar 
            userData={userData}
          />
        </div>
      </header>
    );
  };
  
  export default SubHeader;