import React from 'react'

function KlarifySubHeader({color}) {
  return (
<svg width="26" height="26" viewBox="0 0 26 26" fill="grey" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1349_11556)">
<rect width="3.25" height="19.5" rx="1.625" transform="matrix(0.689666 0.724128 -0.691878 0.722015 24.325 4.3335)" fill={color}/>
<rect width="3.25" height="19.5" rx="1.625" transform="matrix(-0.703853 0.710345 -0.677462 -0.735558 15.498 18.6772)" fill={color}/>
<rect x="0.508301" y="20.8257" width="3.25" height="23.8333" rx="1.625" transform="rotate(-89.4338 0.508301 20.8257)" fill={color}/>
<path d="M11.9006 9.85218V9.68599C11.9048 8.91468 11.9773 8.30105 12.1179 7.84508C12.2628 7.38912 12.4673 7.02264 12.7315 6.74565C12.9957 6.4644 13.3175 6.20659 13.6967 5.97221C13.9567 5.81028 14.1889 5.63343 14.3935 5.44167C14.6023 5.24565 14.7663 5.02832 14.8857 4.78968C15.005 4.54679 15.0646 4.27619 15.0646 3.97789C15.0646 3.64125 14.9858 3.34934 14.8281 3.10218C14.6705 2.85502 14.4574 2.66326 14.1889 2.5269C13.9247 2.39054 13.6286 2.32235 13.3004 2.32235C12.9979 2.32235 12.7102 2.38841 12.4375 2.52051C12.169 2.64835 11.9453 2.84437 11.7663 3.10858C11.5916 3.36852 11.4936 3.69877 11.4723 4.09934H9.19673C9.21804 3.28968 9.41406 2.61213 9.7848 2.06667C10.1598 1.52122 10.6541 1.11213 11.2678 0.839399C11.8857 0.566672 12.5675 0.430308 13.3132 0.430308C14.1271 0.430308 14.843 0.573064 15.4609 0.858576C16.0831 1.14409 16.5668 1.55105 16.9119 2.07946C17.2614 2.6036 17.4361 3.22576 17.4361 3.94593C17.4361 4.43173 17.3572 4.86639 17.1996 5.24991C17.0462 5.63343 16.8267 5.97434 16.5412 6.27264C16.2557 6.57093 15.9169 6.83727 15.5249 7.07164C15.1797 7.28471 14.8963 7.5063 14.6747 7.73642C14.4574 7.96653 14.2955 8.23713 14.1889 8.54821C14.0866 8.85502 14.0334 9.23429 14.0291 9.68599V9.85218H11.9006ZM13.0128 13.8408C12.6293 13.8408 12.299 13.7045 12.022 13.4317C11.745 13.159 11.6065 12.8266 11.6065 12.4346C11.6065 12.051 11.745 11.7229 12.022 11.4502C12.299 11.1775 12.6293 11.0411 13.0128 11.0411C13.392 11.0411 13.7202 11.1775 13.9972 11.4502C14.2784 11.7229 14.419 12.051 14.419 12.4346C14.419 12.6945 14.353 12.931 14.2209 13.1441C14.093 13.3572 13.9226 13.5276 13.7095 13.6555C13.5007 13.779 13.2685 13.8408 13.0128 13.8408Z" fill={color}/>
</g>
<defs>
{/* <clipPath id="clip0_1349_11556">
<rect width="26" height="26" fill="white"/>
</clipPath> */}
</defs>
</svg>
  )
}

export default KlarifySubHeader
