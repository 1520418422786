import React, { Component } from 'react'
import ReactDOM from "react-dom";
import ReactCardCarousel from "react-card-carousel";
import NotiImg from "../../../Assets/PngItem_3497099.png";
import NotiImgs from "../../../Assets/progr.png";
import arunImg from "../../../Assets/nice.png";

export class MyCarousel extends Component {
  render() {
    return (
      <div className='lementstyle'>
      <ReactCardCarousel autoplay={true} autoplay_speed={3000}>
        <div className="stylemyCarsou">
        <img src={NotiImg} className='achiveimg' alt="Avatar" style={{width: "100px"}}/>
        <p className='titleimg pt-3'>
        Karthicka XII B
        </p>
        <p className='pricelisty'>
            1st price
        </p>
        <p className='pricelisty'>
            InterSchool tennis tournament
        </p>
        </div>
        <div className="stylemyCarsou">
            <img src={arunImg} className='achiveimg' alt="Avatar" style={{width: "100px"}}/>
            <h5 className='titleimg pt-3'>
            Lilly XII B
        </h5>
        <p className='pricelisty'>
            1st price
        </p>
        <p className='pricelisty'>
        InterSchool Chess tournament
        </p>
            </div>
        <div className="stylemyCarsou">
            <img src={NotiImgs} className='achiveimg' alt="Avatar" style={{width: "100px"}}/>
            <h5 className='titleimg pt-3'>
            Kalyani Kumari XII B
        </h5>
        <p className='pricelisty'>
            1st price
        </p>
        <p className='pricelisty'>
        State-Level Football tournament
        </p>
            </div> 
      </ReactCardCarousel>
    </div>
    )
  }
}

export default MyCarousel
