import React from 'react'
import { MdVideocam } from "react-icons/md"
import Img from "../../../Assets/Img.png"
import {getUnitSessionNameFromtable} from "../../../services/Apiservices"

// useEffect(() => {
    const ResultData = ({unitID,sessionID}) => {
        const [data,setData] = React.useState('');
        // const [Subdata,setSubData] = React.useState('')

        React.useEffect(()=>{
            const asynccc = async()=>{
                let getUnitVales = {
                    uId: unitID,
                    sId: sessionID,
                };
            
                let getresultUnits = await getUnitSessionNameFromtable(getUnitVales);
                // console.log(getresultUnits,"QQQQQQQQQQQQQQQQQQQQQQQ");
        
                // let variName = await getresultUnits.unit.variableName;
                setData(getresultUnits)
                // setSubData(getresultUnits.session.variableName)
            }
            asynccc()
        },[unitID,sessionID])


    
            return (
                <div>{data.unit ? data.unit.variableName : ''} - {data.session ? data.session.variableName : ''}</div>
            );
        

            
        // let getUnitVales = {
        //     uId: scheduleList.katbookUnitID,
        //     sId: scheduleList.katbookSessionID,
        // };
    
        // let getresultUnits = await getUnitSessionNameFromtable(getUnitVales);
        // // console.log(getresultUnits,"QQQQQQQQQQQQQQQQQQQQQQQ");

        // let variName = await getresultUnits.unit.variableName;
        // return (
        //     <div>{getresultUnits?.unit ? getresultUnits.unit.variableName : ''}</div>
        // );

    }

const TimeTableCard = ({periodList,scheduleList,userimage,bordercolor,lesson,JoinMeeting,cursor,tConvert,currentTime,dateEnable,OpenBook,TableDate}) => {


  return (
    <>
   <div className='d-flex flex-row marginBottom-10' 
    style={{marginBottom :"15px"}}>
        <div className='Time'>
            {tConvert(periodList[0].StartTime)}
            {/* { console.log(dateEnable,"pppppppppppppppppiiii") }
            { console.log(scheduleList,"ppppppppppppppppp") }

            {console.log(currentTime,"DATEEEEEEEEEEEEEEEEEEEEEE")}
            {console.log(periodList[0].EndTime,"DATEEEEEEEEEEEEEEEEEEEEEE")} */}
        </div>

    <div className={"d-flex flex-column w-100"}>
            <div style={{
                paddingRight: "30px"}}>
                <div className={"crosstab marginBottom-5"}style={{borderBottom: "1px solid #D6DDE6",width:"100%"}}>
                </div>
            </div>

        <div className={"English_Time"} style={{
            borderLeftColor : bordercolor.color,
            // backgroundColor:cardcolor,
            cursor: cursor}}
            // onClick={JoinMeeting}
            
            >
                        
            <div style={{display:"flex",flexDirection:"row",marginBottom:"10px",alignItems:"center"}} className={"Gap_Time M_M"}>
                <div 
                onClick={() => {
                OpenBook(scheduleList.katbookUnitID,scheduleList.katbookSessionID,scheduleList.refSubjectID)
                }}
                className={"Eng"} style={{color:bordercolor.color}}>
                {scheduleList.subjectName}
                </div>
                <div className={"Row_Reverse"} >
                    <div onClick={() => {
                        if((dateEnable === 0 && currentTime < periodList[0].EndTime) || dateEnable !== 0) {
                            JoinMeeting(
                            TableDate.split(" ")[1],
                            scheduleList.subjectName,
                            periodList[0].StartTime,
                            periodList[0].EndTime,
                            scheduleList.staffName,
                            scheduleList.staffDesignation,
                            scheduleList.katbookUnitID,
                            scheduleList.katbookSessionID,
                            scheduleList.refStaffID,
                            scheduleList.id,
                            scheduleList.refSubjectID,
                            scheduleList.refSectionID
                        );
                        }
                        // document.querySelector("body").style.overflow = "hidden";
                    }} 
                    style={{marginLeft:"10px",color: dateEnable === 0 ? currentTime < periodList[0].EndTime ? bordercolor.color : '#ccc' : bordercolor.color, cursor: dateEnable === 0 ? currentTime < periodList[0].EndTime ? '' : 'default' : ''}}>
                        <MdVideocam className={"Video_Icon_Size"} />
                    </div>
                    <div  
                    onClick={() => {
                        if((dateEnable === 0 && currentTime < periodList[0].EndTime) || dateEnable !== 0) {
                            JoinMeeting(
                            TableDate.split(" ")[1],
                            scheduleList.subjectName,
                            periodList[0].StartTime,
                            periodList[0].EndTime,
                            scheduleList.staffName,
                            scheduleList.staffDesignation,
                            scheduleList.katbookUnitID,
                            scheduleList.katbookSessionID,
                            scheduleList.refStaffID,
                            scheduleList.id,
                            scheduleList.refSubjectID,
                            scheduleList.refSectionID
                        );
                        }
                    }}
                    className={"AM_Time None_Content"} style={{color:bordercolor.color}}>
                    {tConvert(periodList[0].StartTime)} - {tConvert(periodList[0].EndTime)} 
                    </div>
                </div>
            </div>

            {/* For mobile  */}

            <div  
                onClick={() => {
                    if((dateEnable === 0 && currentTime < periodList[0].EndTime) || dateEnable !== 0) {
                        JoinMeeting(
                        TableDate.split(" ")[1],
                        scheduleList.subjectName,
                        periodList[0].StartTime,
                        periodList[0].EndTime,
                        scheduleList.staffName,
                        scheduleList.staffDesignation,
                        scheduleList.katbookUnitID,
                        scheduleList.katbookSessionID,
                        scheduleList.refStaffID,
                        scheduleList.id,
                        scheduleList.refSubjectID,
                        scheduleList.refSectionID
                    );
                    }
                }}
                className={"AM_Time Web_Display_None"} style={{color:bordercolor.color}}>
                {tConvert(periodList[0].StartTime)} - {tConvert(periodList[0].EndTime)} 
            </div>

            {/* For mobile  */}

            <div 
            onClick={() => {
            OpenBook(scheduleList.katbookUnitID,scheduleList.katbookSessionID,scheduleList.refSubjectID)
            }}
                 className={"D_FLex_Unit"}>
                <div className={"M_T_5px"}>
                    <p className={"Unit_supplementarty"} style={{color:lesson}}>
                        {/* {chapter(scheduleList.katbookUnitID,scheduleList.katbookSessionID)} */}
                        <ResultData unitID={scheduleList.katbookUnitID} sessionID={scheduleList.katbookSessionID} />
                                    {/* {ResultData(scheduleList.katbookUnitID,scheduleList.katbookSessionID)} */}
                    </p>
                </div>
                <div className={"Reverse_User"}>
                    
                    <span className={"Cath_F_S"}>
                        {scheduleList.staffName}&nbsp;&nbsp;
                    </span>
                    <img src={userimage} />
                </div>
            </div>
        </div>
    </div>
    </div>
    </>
  )
  
};

export default TimeTableCard