import React from 'react'
import { useMediaQuery } from 'react-responsive'

function useResponsive() {
    const isBigScreen = useMediaQuery({ minWidth: '768px' });
    const isTabScreen = useMediaQuery({ minWidth: '481px',maxWidth:'1200px',orientation:'landscape' });

    return {isBigScreen,isTabScreen}
}

export default useResponsive