import React, { useEffect, useState } from "react";
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import { CCol } from "@coreui/react";
import Img from "../../Assets/Img.png";
import TimeTableCard from "./Dashboard_folder/TimeTableCard";
import { RiArrowDropUpLine, RiArrowDropDownLine } from "react-icons/ri";
// import ExamSchedule from "./Dashboard_folder/ExamSchedule";
// import Assigments from "./Dashboard_folder/Assigments";
import Example from "./Dashboard_folder/Example";
import MyCarousel from "./Dashboard_folder/MyCarousel";
import LessonCoverage from "../components/LessonCoverage";
import Event from "./Dashboard_folder/Event";
import Exam_Result from "./Dashboard_folder/Exam_Result";
import Birthday from "./Dashboard_folder/Birthday";
import ksmaclogo from "../../Assets/ksmaclogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GetAllStudent,
  fetchSUbjects,
  GetSessionCountbySubject,
  GetQuestionCountbySubject,
} from "../../services/Apiservices";
import { format } from "date-fns";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import kpost from "../../Assets/ksmaclogo.png";
import moment from "moment";
import { Authcontext } from "./Context/Context";

// For ISCON Dashboard
import { TbChartPie2 } from "react-icons/tb";
import {
  BiLineChart,
  BiSearch,
  BiChevronRight,
  BiChevronLeft,
} from "react-icons/bi";
import { Bar, Line, Pie } from "react-chartjs-2";
import { BsListCheck, BsArrowLeftShort } from "react-icons/bs";
import { FiArrowLeft } from "react-icons/fi";
import StLogo from "../../Assets/student_pic.png";
import data from "../../../src/data.json";
import useResponsive from "../../hooks/useResponsive";

const Dashboard = ({
  VideoConfFunc,
  tConvert,
  CuttentDateTime,
  tableBgColors,
  openBookview,
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  trueShowNotification,
}) => {
  const navigate = useNavigate();

  const [userCredsvalues, setuserCredsvalues] = useState("");
  const [Studentinfo, setStudentinfo] = useState([]);
  const [StudentinfoCount, setStudentinfoCount] = useState("");
  const [StudentResData, setStudentResData] = useState([]);
  const [searchStudent, setsearchStudent] = useState("");
  const [filterenable, setfilterenable] = useState(false);

  // For ISCON Dashboard
  const { isBigScreen, isTabScreen } = useResponsive();
  const [listView, setListView] = useState(false);
  const [chartData, setChartData] = useState({});
  const [pieChart, setPieChart] = useState(true);
  const [graph, setGraph] = useState(false);
  const bar_Graph = () => {
    setPieChart(true);
    setGraph(false);
  };
  const Pie_Chart = () => {
    setPieChart(false);
    setGraph(true);
  };

  useEffect(() => {
    console.log(localStorage.length, "PPPPPPPPPPPPPPPPPPPPP");
    if (localStorage.length > 0) {
      let retrData = JSON.parse(localStorage.userCreds);
      console.log(retrData, "PPPPPPPPPPPPPPPPPPPPP");
      setuserCredsvalues(retrData);
      getallStudentInfo(retrData);
    } else {
      const goToHomePage = () => navigate("/login");
      goToHomePage();
    }
  }, [localStorage]);

  const [viewSingleYearStudentCoverge, setViewSingleYearStudentCoverage] =
    useState(null);
  const [filterSingleYearStudentCoverage, setFilterSingleYearStudentCoverage] =
    useState(null);
  const [viewSingleStudentCoverage, setViewSingleStudentCoverage] =
    useState(null);

  const getallStudentInfo = async (user) => {
    let response = await GetAllStudent(user.token);

    let allquestres = await fetchSUbjects();

    if (response.success === true) {
      setStudentResData(response.data);

      console.log(response, "GETALLSTUDENTINFO");
      console.log(Object.keys(response.data), "GETALLSTUDENTINFO");

      let yearLabelArry = [];
      let yearStudentcountArry = [];
      let YearsData = [];
      Object.keys(response.data).map((item, i) => {
        console.log(item, "GETALLSTUDENTINFO");
        yearLabelArry.push(item + " Years");
        yearStudentcountArry.push(response.data[item].length);

        const filteredCourses = allquestres.units.filter(
          (items) => String(items.age) === String(item)
        );
        let res_session_count = 0;
        // let res_session_count = await GetSessionCountbySubject(
        //   filteredCourses[0]["bookId"]
        // );
        let res_question_count = 0;
        // let res_question_count = await GetQuestionCountbySubject(
        //   filteredCourses[0]["bookId"]
        // );

        let newData = {
          age: item,
          bookId: allquestres.success ? filteredCourses[0]["bookId"] : "",
          BookSessionCount:
            filteredCourses[0]["bookId"] && res_session_count.success === true
              ? res_session_count.sessionCount
              : 0,
          QuestionCount:
            filteredCourses[0]["bookId"] && res_question_count.success === true
              ? res_question_count.questionCount
              : 0,
          Year: item + " Years",
          Count: response.data[item].length,
          Percent: Math.round(
            (response.data[item].length / response.totalCount) * 100
          ),
        };

        YearsData.push(newData);
        console.log(response.data[item].length, "GETALLSTUDENTINFO");
      });

      console.log(YearsData, "GETALLSTUDENTINFO");

      console.log("GETALLSTUDENTINFO");
      setStudentinfo(YearsData);
      setStudentinfoCount(response.totalCount);
      getChartData(yearLabelArry, yearStudentcountArry);
    } if(response.status === 401){
      const goToHomePage = () => navigate("/login");
      goToHomePage();
    }
  };

  const getChartData = (labels, studentCount) => {
    setChartData({
      chartData: {
        labels: labels,
        datasets: [
          {
            data: studentCount,
            //backgroundColor:'green',
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(255, 206, 86, 0.6)",
              "rgba(75, 192, 192, 0.6)",
              "rgba(153, 102, 255, 0.6)",
              "rgba(255, 159, 64, 0.6)",
              "rgba(255, 99, 132, 0.6)",
            ],
          },
        ],
      },
    });
  };

  const Data = [
    { Year: "10 Years", Count: "260", Percent: "5%" },
    { Year: "11 Years", Count: "340", Percent: "10%" },
    { Year: "12 Years", Count: "470", Percent: "15%" },
    { Year: "13 Years", Count: "543", Percent: "20%" },
    { Year: "14 Years", Count: "345", Percent: "20%" },
    { Year: "15 Years", Count: "742", Percent: "30%" },
  ];

  let location = useLocation();

  const instDetail = localStorage.getItem("instDetail");
  const userInstDetail = JSON.parse(instDetail);

  const selectSearchStudent = (value) => {
    // alert(value)
    setsearchStudent(value);
    if(value.length > 2){
      setfilterenable(true)
    } else {
      setfilterenable(false)
    }
  }

  // Header Functions End
  return (
    <div
      // style={{overflow: 'hidden'}}
      onClick={() => disableOnOuterTouch()}
    >
      <Header
        showProfile={showProfile}
        // profileIcon={KpIcon}
        // username={state.adminName ? state.adminName : state.user}
        falseShowProfile={falseShowProfile}
        notofShowProfile={notofShowProfile}
        // institutionName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType == 'ADMIN' ? JSON.parse(localStorage.userCreds).user.institutionName.toUpperCase() : JSON.parse(localStorage.userCreds).user.institutionId.institutionName.toUpperCase() : ""}
        personName={
          localStorage.length > 0
            ? JSON.parse(localStorage.userCreds).user.userTypeId.userType ==
              "ADMIN"
              ? JSON.parse(localStorage.userCreds).user.authorizedPerson
              : JSON.parse(localStorage.userCreds).user.fullName
            : ""
        }
        ShowNotifications={ShowNotifications}
        userName={
          localStorage.length > 0
            ? JSON.parse(localStorage.userCreds).user.userName
            : ""
        }
        showNotification={showNotification}
        falseShowNotification={falseShowNotification}
        trueShowNotification={trueShowNotification}
        userType={
          localStorage.length > 0
            ? JSON.parse(localStorage.userCreds).user.userTypeId.userType
            : ""
        }
        userData = {localStorage.length > 0 ? JSON.parse(localStorage.userCreds) : ""}
      />

      <div className={"Dash_home_QWI"}>
        {pieChart && (
          <>
            <div
              // style={{marginLeft:"500px"}}
              className="DCenter"
            >
              <div>
                <span style={{ fontSize: "22px", fontWeight: "600" }}>
                  Total Registrations
                </span>
              </div>

              <div className={"Switch_Day_Week"}>
                <div className={"Switch_Day_Blue"}>
                  <span className={"Switch_Day_Font_Timetable"}>
                    <TbChartPie2 size="20" />
                  </span>
                </div>
                <div className={"Switch_Week_White"} onClick={Pie_Chart}>
                  <span className={"Switch_Week_Font_Timetable"}>
                    <BiLineChart size="20" />
                  </span>
                </div>
              </div>
            </div>

            <div className={"d-flex flex-row align-items-center Chart_Bar_Pie"}>
              <div className={"col-md-7"}>
                <Pie data={chartData.chartData} />
              </div>
              <div className={"col-md-4"}>
                <table
                  style={{
                    width: "100%",
                    textAlign: "center",
                    border: "1px solid",
                  }}
                >
                  <tr>
                    <th>Student Age</th>
                    <th>Count</th>
                    <th>Percentage</th>
                  </tr>

                  {Studentinfo.length > 0 &&
                    Studentinfo.map((x) => {
                      return (
                        <tr>
                          <td>{x.Year}</td>
                          <td>{x.Count}</td>
                          <td>{x.Percent}</td>
                        </tr>
                      );
                    })}
                  <tr style={{ fontWeight: "600" }}>
                    <td>Total</td>
                    <td>{StudentinfoCount}</td>
                    <td>100%</td>
                  </tr>
                </table>
              </div>
            </div>
          </>
        )}

        {graph && (
          <>
            <div
              // style={{marginLeft:"500px"}}
              className="DCenter"
            >
              <div>
                <span style={{ fontSize: "22px", fontWeight: "600" }}>
                  Total Registrations
                </span>
              </div>

              <div className={"Switch_Day_Week"}>
                <div className={"Switch_Day"} onClick={bar_Graph}>
                  <span className={"Switch_Day_Font"}>
                    <TbChartPie2 size="20" />
                  </span>
                </div>
                <div className={"Switch_Week"}>
                  <span className={"Switch_Week_Font"}>
                    <BiLineChart size="20" />
                  </span>
                </div>
              </div>
            </div>

            <div className={"d-flex flex-row align-items-center Chart_Bar_Pie"}>
              <div className={"col-md-7"}>
                <Bar
                  data={chartData.chartData}
                  legend={false}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>
              <div className={"col-md-4"}>
                <table style={{ width: "100%" }}>
                  <tr>
                    <th>Student Age</th>
                    <th>Count</th>
                    <th>Percentage</th>
                  </tr>
                  {Studentinfo.length > 0 &&
                    Studentinfo.map((x) => {
                      return (
                        <tr>
                          <td>{x.Year}</td>
                          <td>{x.Count}</td>
                          <td>{x.Percent}</td>
                        </tr>
                      );
                    })}
                  <tr style={{ fontWeight: "600" }}>
                    <td>Total</td>
                    <td>{StudentinfoCount}</td>
                    <td>100%</td>
                  </tr>
                </table>
              </div>
            </div>
          </>
        )}
      </div>

      <div style={{ marginTop: "30px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h4>Student's Progress</h4>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative", width: 300 }}>
            <BiSearch
              style={{
                position: "absolute",
                left: 70,
                bottom: "25%",
                color: "black",
              }}
            />
            <input
              type="text"
              placeholder="Search Students"
              style={{
                paddingLeft: 30,
                borderRadius: "5px",
                width: "180px",
                height: "38px",
                textAlign: "center",
                // backgroundColor: "black",
                color: "black",
                border: "1px solid",
              }}
              value={searchStudent && searchStudent}
                onChange={(e) => {
                  selectSearchStudent(e.target.value)
                }}
            />
          </div>

          <div>
            <BsListCheck
              onClick={() => {
                setListView(!listView);
              }}
              className="ListIcon"
            />
          </div>
        </div>
      </div>

      {listView && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingLeft: "550px",
          }}
        >
          <div className="py-2 pb-1 ButtonDiv">
            {Studentinfo.length > 0 &&
              Studentinfo.map((x) => {
                return (
                  <div className="pb-2">
                    <button
                      className="Y_Button"
                      onClick={() => {
                        setFilterSingleYearStudentCoverage(x);
                        setListView(!listView);
                      }}
                    >
                      {x.age} Years
                    </button>
                  </div>
                );
              })}
          </div>
        </div>
      )}

      <div className="py-3">
        {filterSingleYearStudentCoverage ? (
          <div>
            {/* Display Table */}
            <div className="py-4">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="col-md-10 ">
                  <div
                    className={`d-flex pr-5 ml-5 ${
                      isTabScreen ? "col-md-11" : "col-md-11"
                    }`}
                    style={{ justifyContent: "left" }}
                  >
                    <button
                      onClick={() => {
                        setFilterSingleYearStudentCoverage(null);
                      }}
                      className="ViewMoreBack ml-5"
                    >
                      <BiChevronLeft size="22" />
                      Back
                    </button>
                  </div>
                  <div className="d-flex flex-row justify-content-center">
                    <div className="d-flex flex-column mr-4 col-md-5 CardStudentDetails Height_List">
                      {viewSingleStudentCoverage ? (
                        <div style={{ padding: "15px" }}>
                          <div>
                            <FiArrowLeft
                              style={{ cursor: "pointer" }}
                              size={"20"}
                              onClick={() => {
                                setViewSingleStudentCoverage(null);
                              }}
                            />
                          </div>
                          <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                            <div className="d-flex flex-column gap-4 align-items-center">
                              <img
                                src={StLogo}
                                width={"100px"}
                                height={"100px"}
                              />
                              <span className="h4">
                                {viewSingleStudentCoverage.fullName}
                                {/* {student.Name} */}
                              </span>
                            </div>

                            <div className="d-flex flex-row mt-5">
                              <LessonCoverage
                                userInfo={viewSingleStudentCoverage}
                                BookId={filterSingleYearStudentCoverage.bookId}
                                isTabScreen={isTabScreen}
                                BookSessionCount={
                                  filterSingleYearStudentCoverage.BookSessionCount
                                }
                                QuestionCount={
                                  filterSingleYearStudentCoverage.QuestionCount
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        StudentResData[
                          Number(filterSingleYearStudentCoverage.age)
                        ].map((student, key) => (
                          <div className={`mr-2 pl-0 `}>
                            {/* {key == 0 && (
                          <div className="StudentYear">
                            
                          </div>
                        )} */}
                            <div
                              style={{
                                display: "flex",
                                paddingTop: "25px",
                                borderBottom: "1px solid #e2e2e2",
                              }}
                            >
                              <div className="pr-3 Img_Name_Size">
                                <div>
                                  <img
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: "50%",
                                    }}
                                    src={StLogo}
                                    alt=""
                                  />
                                </div>

                                <div>
                                  <p className="Img_Name_Font">
                                    {student.fullName}
                                  </p>
                                </div>
                              </div>

                              <LessonCoverage
                                userInfo={student}
                                BookId={filterSingleYearStudentCoverage.bookId}
                                isTabScreen={isTabScreen}
                                BookSessionCount={
                                  filterSingleYearStudentCoverage.BookSessionCount
                                }
                                QuestionCount={
                                  filterSingleYearStudentCoverage.QuestionCount
                                }
                              />
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                    <div className="d-flex flex-column col-md-5 CardStudentDetails Height_List">
                      <div className="d-flex justify-content-center">
                        <span className="ButtonYears_Class_Student">
                          {" "}
                          {filterSingleYearStudentCoverage.age} years{" "}
                        </span>
                      </div>

                      {StudentResData[
                        Number(filterSingleYearStudentCoverage.age)
                      ].map((student, key) => (
                        <div
                          onClick={() => {
                            setViewSingleStudentCoverage(student);
                          }}
                          className={`pl-0`}
                          style={{
                            borderBottom: "1px solid #b7b4b4",
                            padding: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              // paddingTop: "25px",
                            }}
                          >
                            <div
                              className="d-flex flex-row
                               align-items-center w-100 py-3"
                              style={
                                {
                                  // paddingLeft: "25%"
                                }
                              }
                            >
                              <div className="pr-3 cursor-hover">
                                <img
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                  src={StLogo}
                                  alt=""
                                />
                              </div>

                              <div className="d-flex flex-column cursor-hover">
                                <div className="h5">{student.fullName}</div>
                                <div className="h6">{student.email}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* Current Working */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : viewSingleYearStudentCoverge ? (
          <div>
            {/* Display Table */}
            <div className="py-4">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="col-md-10 ">
                  <div
                    className={`d-flex pr-5 ${
                      isTabScreen ? "col-md-11" : "col-md-11"
                    }`}
                    style={{ justifyContent: "left" }}
                  >
                    <button
                      onClick={() => {
                        setViewSingleYearStudentCoverage(null);
                      }}
                      className="ViewMoreBack"
                    >
                      <BiChevronLeft size="22" />
                      &nbsp; {viewSingleYearStudentCoverge.age} Years
                    </button>
                  </div>
                  <div className="d-flex flex-wrap flex-row">
                    {/* Current Working */}
                    {StudentResData[
                      Number(viewSingleYearStudentCoverge.age)
                    ].map((student, key) => (
                      <div
                        className={`CardStudentDetailsViewMore float-left pl-0 marginBottom-10 ${
                          isTabScreen ? "col-md-6" : "col-md-6"
                        }`}
                      >
                        <div
                          className={"SingleStudentData"}
                          style={{
                            display: "flex",
                            paddingTop: "25px",
                          }}
                        >
                          <div className="pr-5 Img_Name_Size">
                            <div>
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "50%",
                                }}
                                src={StLogo}
                                alt=""
                              />
                            </div>

                            <div>
                              <p className="Img_Name_Font">
                                {student.fullName}
                              </p>
                            </div>
                          </div>

                          <LessonCoverage
                            userInfo={student}
                            BookId={viewSingleYearStudentCoverge.bookId}
                            isTabScreen={isTabScreen}
                            BookSessionCount={
                              viewSingleYearStudentCoverge.BookSessionCount
                            }
                            QuestionCount={
                              viewSingleYearStudentCoverge.QuestionCount
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {filterenable ?
            <>
            {Studentinfo.length > 0 &&
              Studentinfo.map((x) => {
                return (
                  <>
                    <div className="py-2">
                      {StudentResData[Number(x.age)].filter(f => f.fullName.toLowerCase().startsWith(searchStudent)).length > 2 && (
                        <div
                          className={`d-flex col-md-11`}
                          style={{ justifyContent: "right" }}
                        >
                          <button
                            onClick={() => {
                              setViewSingleYearStudentCoverage(x);
                            }}
                            className="ViewMoreDetails"
                          >
                            View more&nbsp;
                            <BiChevronRight size="22" />
                          </button>
                        </div>
                      )}

                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="col-md-10 ">
                          <div
                            style={{
                              boxShadow:
                                StudentResData[Number(x.age)].filter(f => f.fullName.toLowerCase().startsWith(searchStudent)).length === 1
                                  ? "none"
                                  : StudentResData[Number(x.age)].filter(f => f.fullName.toLowerCase().startsWith(searchStudent)).length === 2
                                  ? "none"
                                  : "",
                            }}
                            className="d-flex flex-row overflow-hidden Dashboard_Student_Pre_Board"
                          >
                            {/* Current Working */}
                            {StudentResData[Number(x.age)].filter(f => f.fullName.toLowerCase().startsWith(searchStudent)).length > 0 &&
                              StudentResData[Number(x.age)].filter(f => f.fullName.toLowerCase().startsWith(searchStudent)).map(
                                (student, key) => (
                                  <div
                                    className={`CardStudentDetails pl-0 ${
                                      isTabScreen ? "col-md-5" : "col-md-5"
                                    }`}
                                  >
                                    {key == 0 && (
                                      <div className="StudentYear">
                                        {x.age} Years
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        display: "flex",
                                        paddingTop: key !== 0 ? "25px" : "0px",
                                      }}
                                    >
                                      <div className="Img_Name_Size">
                                        <div>
                                          <img
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              borderRadius: "50%",
                                            }}
                                            src={StLogo}
                                            alt=""
                                          />
                                        </div>

                                        <div>
                                          <p className="Img_Name_Font">
                                            {student.fullName}
                                          </p>
                                        </div>
                                      </div>

                                      <LessonCoverage
                                        userInfo={student}
                                        BookId={x.bookId}
                                        isTabScreen={isTabScreen}
                                        BookSessionCount={x.BookSessionCount}
                                        QuestionCount={x.QuestionCount}
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              </>
              :
              <>
              {Studentinfo.length > 0 &&
              Studentinfo.map((x) => {
                return (
                  <>
                    <div className="py-2">
                      {StudentResData[Number(x.age)].length > 2 && (
                        <div
                          className={`d-flex col-md-11`}
                          style={{ justifyContent: "right" }}
                        >
                          <button
                            onClick={() => {
                              setViewSingleYearStudentCoverage(x);
                            }}
                            className="ViewMoreDetails"
                          >
                            View more&nbsp;
                            <BiChevronRight size="22" />
                          </button>
                        </div>
                      )}

                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="col-md-10 ">
                          <div
                            style={{
                              boxShadow:
                                StudentResData[Number(x.age)].length === 1
                                  ? "none"
                                  : StudentResData[Number(x.age)].length === 2
                                  ? "none"
                                  : "",
                            }}
                            className="d-flex flex-row overflow-hidden Dashboard_Student_Pre_Board"
                          >
                            {/* Current Working */}
                            {StudentResData[Number(x.age)].length > 0 &&
                              StudentResData[Number(x.age)].map(
                                (student, key) => (
                                  <div
                                    className={`CardStudentDetails pl-0 ${
                                      isTabScreen ? "col-md-5" : "col-md-5"
                                    }`}
                                  >
                                    {key == 0 && (
                                      <div className="StudentYear">
                                        {x.age} Years
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        display: "flex",
                                        paddingTop: key !== 0 ? "25px" : "0px",
                                      }}
                                    >
                                      <div className="Img_Name_Size">
                                        <div>
                                          <img
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              borderRadius: "50%",
                                            }}
                                            src={StLogo}
                                            alt=""
                                          />
                                        </div>

                                        <div>
                                          <p className="Img_Name_Font">
                                            {student.fullName}
                                          </p>
                                        </div>
                                      </div>

                                      <LessonCoverage
                                        userInfo={student}
                                        BookId={x.bookId}
                                        isTabScreen={isTabScreen}
                                        BookSessionCount={x.BookSessionCount}
                                        QuestionCount={x.QuestionCount}
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              </>
            }
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
