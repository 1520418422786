import React from 'react'
import { MdVideocam } from "react-icons/md"
import {getUnitSessionNameFromtable} from "../../../services/Apiservices"

// useEffect(() => {
    const ResultData = ({unitID,sessionID,fontcolor,cursor,OpenBook,subjectId}) => {
        const [data,setData] = React.useState('');
        // const [Subdata,setSubData] = React.useState('')

        React.useEffect(()=>{
            const asynccc = async()=>{
                let getUnitVales = {
                    uId: unitID,
                    sId: sessionID,
                };
            
                let getresultUnits = await getUnitSessionNameFromtable(getUnitVales);
                // console.log(getresultUnits,"QQQQQQQQQQQQQQQQQQQQQQQ");
        
                // let variName = await getresultUnits.unit.variableName;
                setData(getresultUnits)
                // setSubData(getresultUnits.session.variableName)
            }
            asynccc()
        },[unitID,sessionID])


    
            return (
                <>
                <div 
                onClick={() => {
                OpenBook(unitID,sessionID,subjectId)
                }}
                className={"Unit_Supp"} style={{color: fontcolor,cursor: cursor}} >
                    {data.unit ? data.unit.variableName : ''}
                </div>

                <div 
                onClick={() => {
                OpenBook(unitID,sessionID,subjectId)
                }}
                className={"Unit_Sup"} style={{color: fontcolor,cursor: cursor}} >
                {data.session ? data.session.variableName : ''}
                </div>
                </>
            );
        
    }

const TimeTableWeekTeacher = ({format,day,subjectdate,subjectData,currentDate,currentdateexists,currentTime,JoinMeeting,OpenBook,cursor,tConvert,peroidList,colorsList,subject1,iconcolor,color,fontcolor,bordercolor1,cardcolor,
    subject2,subject3,subject4,subject5,subject6,subject7,subject8,bordercolor2,bordercolor3,
    bordercolor4,bordercolor5,bordercolor6,bordercolor7,bordercolor8,user1,image
}) => {

    console.log(currentTime,"DATEEEEEEEEEEEEEEEEEEEEEE");
    
  return (
    <div>
        <div className={"Pad_Px"} >

            <div className={"Display_Flex"}>
                <div className={"D_D_M"}>
                    <span className={"Day_Month WeekDate"}>
                         {day}
                         <span className={""} >,</span>
                    </span>
                    <span className={"Day_Month"}>
                         {subjectdate.split("/")[0]+" "+subjectdate.split("/")[1]}
                    </span>
                </div>

                <div className={"F_J_C"} >

                    { subjectData.map((x, i) => (
                        <>

                        {/* {console.log(peroidList.filter((q) => String(q.PeriodNumber) === String(x.period))[0].EndTime,"DATEEEEEEEEEEEEEEEEEEEEEE")}
                        {console.log(peroidList,"DATEEEEEEEEEEEEEEEEEEEEEE")}
                        {console.log(x,"DATEEEEEEEEEEEEEEEEEEEEEE")} */}
                        {/* <div style={{ width: "12%"}}>
                        <div className={"Flex_Row_Period"} style={{ width: "100%"}}>
                        <div className={"Class_Period"}>
                        <span className={"Period_Font"}>
                            Period {peroidList[i].period}
                        </span>
                        <span className={"Period_Time"}> 
                        ({tConvert(peroidList.filter((q) => String(q.PeriodNumber) === String(x.period))[0].StartTime)} - {tConvert(peroidList.filter((q) => String(q.PeriodNumber) === String(x.period))[0].EndTime)})
                        </span>
                        </div>
                        </div> */}

                        <div className={"Eng_Video"} style={{
                            borderLeftColor : colorsList[i].color,
                            // backgroundColor: colorsList[i].color,
                        }}>

<div className={"Class_Period"}>
                        <span className={"Period_Font"}>
                            Period {peroidList.filter((q) => String(q.PeriodNumber) === String(x.period))[0].PeriodNumber}
                        </span>
                        {/* <span className={"Period_Time"}> 
                        ({tConvert(peroidList.filter((q) => String(q.PeriodNumber) === String(x.period))[0].StartTime)} - {tConvert(peroidList.filter((q) => String(q.PeriodNumber) === String(x.period))[0].EndTime)})
                        </span> */}
                        </div>

                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                                <div
                                onClick={() => {
                                OpenBook(x.katbookUnitID,x.katbookSessionID,x.refSubjectID)
                                }}
                                >
                                    <span className='E_Font' style={{color:colorsList[i].color,cursor: cursor}}>
                                        {x.subjectName}
                                    </span>
                                </div>
                                <div
                                onClick={() => {
                                    if((currentdateexists === 0 && currentTime < peroidList.filter((q) => String(q.PeriodNumber) === String(x.period))[0].EndTime) || currentdateexists !== 0) {
                                        // alert(1)
                                        JoinMeeting(
                                        subjectdate,
                                        x.subjectName,
                                        peroidList.filter((q) => String(q.PeriodNumber) === String(x.period))[0].StartTime,
                                        peroidList.filter((q) => String(q.PeriodNumber) === String(x.period))[0].EndTime,
                                        x.staffName,
                                        x.staffDesignation,
                                        x.katbookUnitID,
                                        x.katbookSessionID,
                                        x.refStaffID,
                                        x.id,
                                        x.refSubjectID,
                                        x.refSectionID
                                    );
                                    }
                                    // document.querySelector("body").style.overflow = "hidden";
                                }} 
                                >
                                    <MdVideocam size="25" className={"Videocam_Size"}
                                    style={{color: currentdateexists === 0 ? currentTime < peroidList.filter((q) => String(q.PeriodNumber) === String(x.period))[0].EndTime ? colorsList[i].color : '#ccc' : colorsList[i].color, cursor: currentdateexists === 0 ? currentTime < peroidList.filter((q) => String(q.PeriodNumber) === String(x.period))[0].EndTime ? cursor : 'default' : cursor}}/>
                                </div>

                                


                            </div>

                            <div  
                                                    onClick={() => {
                                    if((currentdateexists === 0 && currentTime < peroidList.filter((q) => String(q.PeriodNumber) === String(x.period))[0].EndTime) || currentdateexists !== 0) {
                                        // alert(1)
                                        JoinMeeting(
                                        subjectdate,
                                        x.subjectName,
                                        peroidList.filter((q) => String(q.PeriodNumber) === String(x.period))[0].StartTime,
                                        peroidList.filter((q) => String(q.PeriodNumber) === String(x.period))[0].EndTime,
                                        x.staffName,
                                        x.staffDesignation,
                                        x.katbookUnitID,
                                        x.katbookSessionID,
                                        x.refStaffID,
                                        x.id,
                                        x.refSubjectID,
                                        x.refSectionID
                                    );
                                    }
                                    // document.querySelector("body").style.overflow = "hidden";
                                }} 
                    className={"AM_Time None_Content"} style={{color:colorsList[i].color}}>
                    {tConvert(peroidList.filter((q) => String(q.PeriodNumber) === String(x.period))[0].StartTime)} - {tConvert(peroidList.filter((q) => String(q.PeriodNumber) === String(x.period))[0].EndTime)}
                    </div>

                            
                            
                            <div 
                            
                            
                            className={"Col_Gap"}>

                            <ResultData unitID={x.katbookUnitID} sessionID={x.katbookSessionID} fontcolor={fontcolor} cursor={cursor} OpenBook={OpenBook} subjectId={x.refSubjectID} />
                                {/* <div className={"Unit_Supp"} style={{color: fontcolor}} >
                                    Unit 2 - Suplementary
                                </div>

                                <div className={"Unit_Sup"} style={{color: fontcolor}} >
                                    Chapter 7 - The midnight visitor
                                </div> */}
                            </div>
                            <div 
                            onClick={() => {
                                OpenBook(x.katbookUnitID,x.katbookSessionID,x.refSubjectID)
                                }}
                            className={"Web_Display_None Week_User_Img"}>
                                <img src={image} />
                                <span className={"Cath_F_S"}>
                                    {user1}
                                </span>
                            </div>
                        </div>

                        {/* </div> */}
                    </>
                    ))}

                </div>
            </div>






        </div>
    </div>
  )
};

export default TimeTableWeekTeacher