import React,{Fragment} from 'react';
import Select from 'react-select';
import "./KDropdown.css"

function KDropdown({containerStyle,error,dropDownStyle,dropDownClassName,labelStyle,labelClassName,containerClassName,label,isRequired,onChange,value,options,isDisabled,placeholder,autoFocus,...props }) {
  console.log(value,"DROPDOWN VALUES");
  console.log();
  return (
    
    <div style={{...containerStyle}} className={`d-flex flex-column ${containerClassName} `}>
        <label className={`width-fit  ${labelClassName} ${isRequired ? 'required-field' : ''} h6 `}
            style={{...labelStyle}}
        >{label}</label>
        <Select 
        className={`w-100 ${dropDownClassName}`}
        style={{...dropDownStyle}}
        onChange={(event) => onChange(event)}
        value={value}
        options={options}
        isDisabled = {isDisabled}
        placeholder={placeholder}
        autoFocus={autoFocus}
        {...props}
        />
         <div className="text-danger">{error && error}</div>
    </div>
  )
}

KDropdown.defaultProps = {
    isRequired: false,
    label: "",
    // value: null,
    onChange : () => {},
    // options : [],
    autoFocus : false,
    placeholder: "",
    isDisabled : false,
    labelClassName :'',
    labelStyle :{},
    dropDownClassName:'',
    dropDownStyle:{},
    error: '' 
}


export default KDropdown