import React, {useEffect,useState} from "react";
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import { useMediaQuery } from "react-responsive";
import useResponsive from "../../hooks/useResponsive";
import KDropdown from "./common/KDropdown";
import KInput from "./common/KInput";
import KHeader from "./common/KHeader";
import KButton from "./common/KButton";
import { Row, Col } from 'reactstrap';
import KEditor from "./common/KEditor";
import KRequiredLabel from "./common/KRequiredLabel";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import data from "../../../src/data.json";
import { CModal } from "@coreui/react";
import { toast } from "react-toastify";
import Danger from "../components/Toastify/Danger";
import Warning from "../components/Toastify/Warning";
import Success from "../components/Toastify/Success";
import parse from "html-react-parser";

import {
  createQuestionType,
  getQuestionTypeByInst,
  updateQuestionType,
  getTeacherSubjects,
  getExamListByCourse,
  PostExam,
  createNewExam,
  createNewQuestionByExam,
  getQuestionByStaff,
  fetchSUbjects
} from "../../services/Apiservices"

const PostAssignment = ({
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
}) => {
  const { isBigScreen } = useResponsive();
  const [Post, setPost] = useState(false);
  const [visible, setVisible] = useState(false);
  const [subjectsData, setsubjectsData] = React.useState([]);
  const [singleSubject, setsingleSubject] = React.useState([]);
  const [examsList, setexamsList] = React.useState([]);
  const [singleExamID, setsingleExamID] = React.useState([]);
  const [AsstDate, setAsstDate] = useState('');
  const [userscredsData, setuserscredsData] = useState([]);
  const [getAllQues, setgetAllQues] = useState([]);
  const [ExamSTime, setExamSTime] = useState('');
  const [ExamETime, setExamETime] = useState('');

  const [questionTypesOption, setQuestionTypesOption] = useState('');
  const [questionType, setQuestionType] = useState('');
  const [detailtedans, setDetailtedans] = useState("");

  const [editOPtion, setEditOption] = useState(false)
  const [editOPtionText, setEditOptionText] = useState('')
  const [optionText, setoptionText] = React.useState("");
  const [InstructionsTxt, setInstructionsTxt] = React.useState("");
  
  const [option, setoption] = React.useState([]);
  const [correctAns, setCorrectAns] = React.useState({});
  const [textAns, setTextAns] = React.useState("");
  const [quesAns, setquesAns] = React.useState({});
  const [QuestionValue, setQuestionValue] = useState("");
  const [Quesmarks, setQuesmarks] = useState('');
  const optionsAns = [
    {
      value: "TextBox",
      id: "1",
      label: "TextBox",
    },
    { value: "TextArea", id: "2", label: "TextArea" },
    { value: "RadioButton", id: "3", label: "RadioButton" },
  ];
  const [AnswerType, setAnswerType] = useState({});
  const [updatedData, setupdatedData] = useState({});
  const [updatedContent, setupdatedContent] = useState(false);

  const Cancelpostfunc = async () => {
    setsingleSubject('')
    setsingleExamID('')
    setAsstDate('')
    setgetAllQues([])
    setInstructionsTxt("")
    setExamSTime("")
    setExamETime("")
  }

  const Cancelformfunc = async () => {
    setupdatedData({})
    setupdatedContent(false)
    setQuestionValue('')
    setQuestionType('')
    setQuesmarks('')
    setoption([])
    setTextAns('')
    setquesAns('')
    setCorrectAns('')
  }

  const postQuestionsFunc = async () => {
    let result;
    let body = {
      assignmentId: singleExamID.assignmentId,
      posted: true,
      examDate: AsstDate,
      examStartTime: ExamSTime,
      examEndTime: ExamETime,
      instruction: InstructionsTxt
    };
    console.log(body,"POST EXAMS LIST");
    try {
      result = await PostExam(body);
      if (result.success) {
        Cancelformfunc()
        Cancelpostfunc()
      } else {
        toast.error(<Danger body={result.error} />);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const getAllQuestionTypeFuc = async () => {
    let result;
    try {
      result = await getQuestionTypeByInst();
      if (result.success) {
        console.log(result,"GET ALL QUESTION TYPES");
        let dataOPtion = await result.data.map((e) => {
          return { value: e.questionTypeName, label: e.questionTypeName, _id: e._id };
        });

        setQuestionTypesOption(dataOPtion);
      } else {
        toast.error(<Danger body={result.error} />);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const updateFunc = async (data) => {
    console.log(data,"UPDATE CONTENT DATA");
    getAllQuestionTypeFuc()

    setQuestionType({
      value: questionTypesOption.filter((item) => item._id === data.questionTypeId)[0].value, 
      label: questionTypesOption.filter((item) => item._id === data.questionTypeId)[0].value, 
      _id: data.questionTypeId
    })
    setupdatedData(data)
    setupdatedContent(true)
    setquesAns(data.correctAnswer);
    setQuestionValue(data.question)
    setQuesmarks(data.marks)
    console.log(questionTypesOption.filter((item) => item._id === data.questionTypeId)[0].value,"UPDATE CONTENT DATA");
    setAnswerType({
      value: data.answerType, 
      label: data.answerType, 
      id: optionsAns.filter((item) => String(item.value) === String(data.answerType))[0].id
    })
    var listopt = [];
    data.options.map((x) => {
      var objData = 
      { _id: x._id, name: x.option.trim(), value: parse(x.option.trim()), label: parse(x.option.trim()) }
      listopt.push(objData)
    });


    setoption(listopt)
    console.log(data.options.filter((item) => String(item.option) === String(data.correctAnswer))[0]._id,"UPDATE CONTENT DATA");
    setCorrectAns({
      value: parse(data.correctAnswer.trim()), 
      label: parse(data.correctAnswer.trim()), 
      name: data.correctAnswer.trim(), 
      _id: data.options.filter((item) => String(item.option) === String(data.correctAnswer))[0]._id
    })
    console.log({
      value: data.answerType, 
      label: data.answerType, 
      id: optionsAns.filter((item) => item.value === data.answerType)[0].id
    },"UPDATE CONTENT DATA");
  }

  const onChangeQuestion = async (name, e) => {
    console.log("ASDSAD", name, e);
    setQuestionValue(e);
    if (e !== "") {
    }
  };

  const onChangeAnswerType = async (name, e) => {
    setEditOption(false);
    setEditOptionText('')
    console.log(name, e);
    setoption([]);
    setCorrectAns({});
    setTextAns("");
    setAnswerType(e);
  };

  const addoption = async () => {
    console.log(optionText, "tttt");
    console.log(option, "tttt");
    console.log(optionText.trim(), "tttt");
    if (optionText !== "") {
      var count = 0;
      await option.map((data) => {
        if (optionText.trim() !== data.name) {
          count = count + 1;
        }
        return 1;
      });
      console.log("count", option.length, count);
      if (option.length !== count) {
        toast.warning(<Warning body={"Already this option value was added"} />);
      } else {
        console.log(optionText, "jjj");
        await setoption([
          ...option,
          { _id: count + 1, name: optionText.trim(), value: parse(optionText.trim()), label: parse(optionText.trim())  },
        ]);
        await setoptionText('')
        await setCorrectAns('');
      }
    }
  };

  const editOPtionFuc = async () => {
    console.log(optionText, "tttt");
    const findIndex = await option.findIndex(x => {
      if (String(x._id) === String(editOPtionText._id)) { return true; } return false;
    });
    if (findIndex >= 0) {
      if (optionText !== "") {
        var count = 0;
        await option.map((data) => {
          if (optionText.trim() !== data.name) {
            count = count + 1;
          }
          return 1;
        });
        console.log("count", option.length, count);
        if (option.length !== count) {
          toast.warning(<Warning body={"Already this option value was added"} />);
        } else {
          console.log(optionText, "jjj");

          if (String(option[findIndex].name).toLocaleLowerCase().trim() !== String(optionText).toLocaleLowerCase().trim()) {
            let data = await option.map(x => {
              if (String(x._id) === String(editOPtionText._id)) {
                return { _id: x._id, name: optionText.trim(), value: parse(optionText.trim()), label: parse(optionText.trim()) }
              }
              else {
                return { _id: x._id, name: x.name.trim(), value: parse(x.name.trim()), label: parse(x.name.trim()) }
              }
            });

            await setoption('');
            await setoptionText('');
            await setCorrectAns('');
            await setEditOptionText('');
            await setEditOption('');
            await setoption(data);
            console.log(data,"tttt");
          }
          else {
            toast.warning(<Warning body={'Edit something to update the option!'} />);
          }
        }
      }
    }
    else {
      toast.warning(<Warning body={"Select vaild option text!"} />);
    }
  };

  const onChangeFuc = (e) => {
    console.log(e);
    if (editOPtion) {
      setoptionText(e);
    }
    else {
      setoptionText(e);
    }
  };

  const onChangeInstrusFuc = (e) => {
    console.log(e);
    setInstructionsTxt(e);
  };

  const getAllQuestionsFuc = async (value) => {
    // alert(singleExamID.assignmentId)
    setAsstDate(value)
    let result;
    let body = {
      assignmentId: singleExamID.assignmentId,
      staffId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).user._id,
    };
    try {
      result = await getQuestionByStaff(body);
      if (result.success) {
        console.log(result,"GET ALL QUESTION DATAS");
        setgetAllQues(result.data);
      } else {
        toast.error(<Danger body={result.error} />);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const CourseListFunc = async (userType) => {

    if(userType === 'TEACHER'){

      let allquestres = await  fetchSUbjects();
      if(allquestres.units.length > 0){
        let dataOPtion = await allquestres.units.map((e) => {
          return { _id: e._id, age: e.age, bookName: e.book, value: 'Age '+ e.age+' - '+e.book, label: 'Age '+ e.age+' - '+e.book };
        });

        setsubjectsData(dataOPtion);
      }

    } else {

      let allquestres = await  fetchSUbjects();
      if(allquestres.units.length > 0){
        let dataOPtion = await allquestres.units.map((e) => {
          return { _id: e._id, age: e.age, bookName: e.book, value: 'Age '+ e.age+' - '+e.book, label: 'Age '+ e.age+' - '+e.book };
        });

        setsubjectsData(dataOPtion);
      }

    }

    
  }

  const ExamsListFunc = async (data) => {
    if(data.length > 0){
      let dataOPtion = await data.map((e) => {
        return { value: e.examName, label: e.examName, assignmentId: e._id, marks: e.totalMarks };
      });
      console.log(dataOPtion,"GET ALL EXAMS LIST");
      setexamsList(dataOPtion);
    } else {
      console.log("","GET ALL EXAMS LIST");
      setexamsList([]);
    }
  }

  const getExamsDataFunc = async (course) => {
    setsingleExamID([])
    setexamsList([]);
    setsingleSubject(course);
    let result;
    let body = {
      katbookSubjectId: course._id
    };
    try {
      result = await getExamListByCourse(body);
      if (result.success) {
        console.log(result,"GET ALL EXAMS LIST");
        ExamsListFunc(result.data.filter((item) => item.posted === false))
        setAsstDate('')
        setgetAllQues([])
      } else {
        toast.error(<Danger body={result.error} />);
      }
    } catch (error) {
      console.log(error);
    }
    
  }

  useEffect(() => {

    if(localStorage.length > 0){

      let retrData = JSON.parse(localStorage.userCreds);
      setuserscredsData(retrData);
      CourseListFunc(retrData.user.userTypeId.userType)

    }
    
  },[]);

  return (
    <div onClick={() => disableOnOuterTouch()}>
      <div className="Navbar">
        <Header 
        showProfile={showProfile}
        // profileIcon={KpIcon}
        // username={state.adminName ? state.adminName : state.user}
        falseShowProfile={falseShowProfile}
        notofShowProfile={notofShowProfile}
        // institutionName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType == 'ADMIN' ? JSON.parse(localStorage.userCreds).user.institutionName.toUpperCase() : JSON.parse(localStorage.userCreds).user.institutionId.institutionName.toUpperCase() : ""}
        personName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType == 'ADMIN' ? JSON.parse(localStorage.userCreds).user.authorizedPerson : JSON.parse(localStorage.userCreds).user.fullName : ""}
        ShowNotifications={ShowNotifications}
        userName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userName : ""}
        showNotification={showNotification}
        falseShowNotification={falseShowNotification}
        userType = {localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType : ""}
      />
      </div>
      <div className={"Dash_home_QWI"}>
        <div
          className="myattendcard2w p-3"
          style={{
            height: window.innerHeight - 117 + "px",
            overflowY: "auto",
          }}
        >
          <KHeader title="Post Exam" containerClassName={`marginTop-20`} />

          <div className="container-fluid px-5 py-3">
            <div className="d-flex flex-column flex-md-row col-9">
              <KDropdown
                containerClassName="w-100"
                label="Select Course"
                isRequired={true}
                placeholder="Select Course"
                value={singleSubject}
                options={subjectsData}
                onChange={(e) => {
                  getExamsDataFunc(e)
                  }}
              />
              <KDropdown
                containerClassName="pl-md-3 w-100"
                label="Select Exam"
                isRequired={true}
                placeholder="Select Exam"
                value={singleExamID}
                options={examsList}
                onChange={(e) => {
                  setsingleExamID(e);
                  setAsstDate('')
                  setgetAllQues([])
                  }}
              />
              <KInput
                label="Total Marks"
                isRequired={true}
                placeholder="Enter Total Marks"
                containerClassName="pl-md-3 w-100"
                // containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 ${
                //   isBigScreen ? "w-50" : "w-100"
                // }`}
                labelClassName={"width-maxContent"}
                inputClassName="w-100"
                value={singleExamID ? singleExamID.marks : ""}
                toast={toast}
                Warning={Warning}
                readonly={true}
              />
            </div>
            <div className="d-flex flex-column flex-md-row marginTop-20">
              
              <KInput
                label="Exam Date"
                containerClassName="pl-0 pt-2 pt-md-0 w-100"
                isRequired={true}
                value={AsstDate}
                // onChange={setAsstDate}
                onChange={(e) => {
                  getAllQuestionsFuc(e)
                }}
                type="date"
                min="1997-01-01"
                max="2030-12-31"
              />
              <KInput
                label="Exam Start Time"
                isRequired={true}
                placeholder=""
                containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 ${
                  isBigScreen ? "w-50" : "w-100"
                }`}
                onChange={(e) => {
                  setExamSTime(e)
                }}
                value={ExamSTime}
                labelClassName={"width-maxContent"}
                inputClassName="w-100"
                type="time"
              />
              <KInput
                label="Exam End Time"
                isRequired={true}
                placeholder=""
                containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 ${
                  isBigScreen ? "w-50" : "w-100"
                }`}
                onChange={(e) => {
                  setExamETime(e)
                }}
                value={ExamETime}
                labelClassName={"width-maxContent"}
                inputClassName="w-100"
                type="time"
              />
            </div>
            <div className={"w-100"}><KRequiredLabel
              title="Add Exam Instruction"
              className="h5 mt-3"
              style={{
                display: "inline-block",
                alignSelf: "flex-end"
              }}
            /></div>
            <div className="d-flex flex-column flex-md-row marginTop-5">
            
            <KEditor
                        
              height={isBigScreen ? 400 : 500}
              value={InstructionsTxt}
              handleEditorChange={(
                content
              ) => {
                onChangeInstrusFuc(content);
              }}
            />
            </div>
          </div>

          {updatedContent && 
          <>
          <KHeader title="Add Questions" />
          <div className="container-fluid px-5 py-3">
              <div className="d-flex flex-column flex-md-row marginTop-20">
              <KDropdown
                  containerClassName="w-100"
                  label="Select Question Type"
                  isRequired={true}
                  edit={questionType ? true : false}
                  value={questionType && questionType}
                  options={questionTypesOption}
                  placeholder="Select Question Type"
                  labelStyle={{
                    fontSize: "20px",
                    marginBottom: "15px"
                  }}
                  onChange={(e) => {
                      // setUpdateData(data)
                      console.log(e,"oooooooooooooooooo")
                      setQuestionType(e);
                    }}
                />
                <div className="w-100 pt-3 pt-md-0 d-flex align-items-end pl-0 pl-md-3 cursor-pointer">
              
              </div>
              </div>

              <div className="d-flex flex-row justify-content-between my-3">
                        <KRequiredLabel
                          title="Enter Question"
                          className="h5 mt-3"
                          style={{
                            display: "inline-block",
                            alignSelf: "flex-end"
                          }}
                        />
                        <KInput
                          label="Marks"
                          isRequired={true}
                          placeholder="Enter Marks"
                          containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 w-25`}
                          labelClassName={"width-maxContent"}
                          inputClassName="w-25"
                          type="number"
                          value={Quesmarks}
                          onChange={setQuesmarks}
                          toast={toast}
                          Warning={Warning}
                        />
              </div>
              <KEditor 
              height={isBigScreen ? 400 : 500}
              value={QuestionValue}
              handleEditorChange={(
                    content
                  ) => {
                    onChangeQuestion("Question", content)
                  }}

              />
              <KDropdown
                labelStyle={{
                  fontSize: "20px",
                  marginBottom: "15px"
                }}
                containerClassName={`marginTop-20 ${
                  isBigScreen ? "w-25" : "w-100"
                }`}
                label="Answer Type"
                isRequired={true}
                placeholder="Select Answer Type"
                options={optionsAns}
                value={AnswerType}
                onChange={(e) => onChangeAnswerType("AnswerTypes", e)}
                isDisabled={QuestionValue ? false : true}
                
              />

              {AnswerType && AnswerType.value === "RadioButton" && (
                    
                      <>
                      <Row style={{
                      marginTop: '17px',
                      color: 'black',
                    }}>
                      <Col sm="10" md="10" lg="7">
                        <div
                          style={{ marginBottom: "20px", color: "black" }}
                        >
                          <KRequiredLabel
                              title="Options"
                              className="h5 mt-3"
                              style={{
                                display: "inline-block",
                                alignSelf: "flex-end"
                              }}
                            />
                        </div>
                        {/* <CInput
                              style={{ marginTop: '8px' }}
                              onChange={(e) => {
                                onChangeFuc(e);
                              }}
                              value={optionText}
                              placeholder={"Options"}
                            /> */}
                        <KEditor
                        
                          height={isBigScreen ? 400 : 500}
                          value={optionText}
                          handleEditorChange={(
                            content
                          ) => {
                            onChangeFuc(content);
                          }}
                        />
                        </Col>

                      <Col sm="2" md="2" lg="4">
                      <KButton 
                        width={60} 
                        height={50} 
                        value={editOPtion ? 'UPDATE' : 'ADD'}
                        className={`px-3 py-2 ${isBigScreen ? "w-auto" : "w-100"}`}
                        style={{
                          position: 'absolute',
                          bottom: '0px',
                          marginBottom: '20px'
                        }} 
                        onClick={() => {
                          if (optionText) {
                            if (editOPtion) {
                              editOPtionFuc();
                            }
                            else {
                              addoption();
                            }
                          } else {
                            toast.warning(<Warning body={"Enter Your Option Name"} />);
                          }
                        }} 
                        />
                          </Col>

</Row>
                          </>
              )}

              {option.length !== 0 &&
                AnswerType.value === "RadioButton" && (
                  <Row style={{
                    marginTop: '17px',
                    color: 'black',
                    paddingLeft: '18px'
                  }}>
                    <Col sm="12" md="6" lg="3" style={{ marginTop: '15px', marginBottom: '15px' }}>
                    {/* <div style={{ marginBottom: '10px', color: 'black' }}>
                        <h6 style={{ fontWeight: 'bold' }}>{name}<span className="text-danger">{star}</span></h6>
                    </div> */}
                    {/* <KRequiredLabel
                          title="Correct Answers in Options"
                          className="h5 mt-3"
                          style={{
                            display: "inline-block",
                            alignSelf: "flex-end"
                          }}
                        /> */}
                    <KDropdown
                      label={"Correct Answers in Options *"}
                      options={option}
                      // value={correctAns}
                      value={correctAns}
                      onChange={(e) => {
                        setCorrectAns(e);
                        console.log(e,"WWWWWWWWWWWWWW");
                      }}
                      placeholder={"Select correct answer"}
                    />
                    </Col>
                    <Col sm="2" md="2" lg="2">
                      {!editOPtion ? <KButton width={60} height={50} className="btn btn-info" style={{
                        position: 'absolute',
                        bottom: '0px',
                        marginBottom: '20px'
                      }} onClick={() => {
                        if (correctAns && correctAns._id) {
                          console.log(correctAns);
                          setEditOption(true);
                          setEditOptionText(correctAns);
                          setoptionText(correctAns.name)
                        }
                        else {
                          toast.warning(<Warning body={'Please edit an option which your are edit!.'} />)
                        }
                      }} 
                      value="EDIT"


                      />
                        : <KButton width={60} height={50} className="btn btn-danger" style={{
                          position: 'absolute',
                          bottom: '0px',
                          marginBottom: '20px'
                        }} onClick={() => {
                          setEditOption(false);
                          setEditOptionText('');
                          setoptionText('');
                        }}
                        value="CANCEL"
                        />
                      }
                    </Col>

                  </Row>
              )}


        
              {AnswerType &&
              (AnswerType.value === "TextBox" ||
              AnswerType.value === "TextArea") ? (
                <>
              <KRequiredLabel
                title="Correct Answer"
                className="h5 marginTop-20"
              />
              <KEditor 
              height={isBigScreen ? 400 : 500}
              value={textAns || quesAns}
              handleEditorChange={(
                content
              ) => {
                setquesAns(content);
              }}

              />
              </>
              ) : null}

              <div className="d-flex flex-column flex-md-row mt-3" style={{ paddingTop: "30px", paddingBottom: "100px", float: "right"}}>
                <KButton
                  value="UPDATE"
                  onClick={() => {
                    // createNewQuestionsFunc()
                  }}
                  btnColor={"green"}
                  className={`px-4 py-2 `}
                />
                <KButton
                  onClick={() => {
                    Cancelformfunc()
                  }}
                  value="CANCEL"
                  className={`px-4 py-2 ${isBigScreen ? "ml-3" : "mt-2"}`}
                  btnColor={"red"}
                />
              </div>
            
          </div>
          </>
          }

          {!updatedContent && 
          <>
          <div className="container-fluid px-3 py-3">
            {getAllQues.length > 0 && (
              <div>
                <div className="d-flex flex-row justify-content-between align-items-center pb-2">
                  <KHeader
                    title="Questions & Answers"
                    style={{
                      marginLeft: "0px !important",
                    }}
                  />
                  <KButton
                    value="Add"
                    onClick={() => {
                    }}
                    className={"height-fitContent px-4 py-2 "}
                  />
                </div>

                {getAllQues.map((items, index) => (
                  <div className="mx-5 my-3 ques_ans">
                    <div className="d-flex flex-row gap-3 align-items-center">
                      <div className="DivBox">
                        {/* Question 1 */}
                        <div className={"w-100 d-flex"}>
                          <div className={"w-100 d-flex"}>
                          <div><b>Question {index + 1}:&nbsp;&nbsp;</b></div>
                          </div>
                          <div className={"d-flex"}><b>Marks:&nbsp;&nbsp;</b>10</div>
                        </div>
                        <div className={"w-100"}>{parse(items.question)}</div>
                        {console.log(items.options,"OPTIONSSSSSSSSSSSSSSSSSS")}
                        {items.options.map((q, index1) => (
                          <span style={{ display: "flex"}}>{index1 + 1})&nbsp;&nbsp;{parse(q.option)}</span>
                        ))}
                        {/* {parse(items.options)} */}
                        <div className="mt-3">
                      {/* Answer 1 */}
                      <div style={{ display: "flex"}}><b>Answer :&nbsp;&nbsp;</b>{parse(items.correctAnswer)}</div>
                    </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          paddingLeft: "145px",
                          gap: "30px",
                        }}
                      >
                        <div className="borderCircleEdit"
                        onClick={() => {
                          updateFunc(items)
                        }}
                        >
                          <AiOutlineEdit size="25" />
                        </div>

                        <div className="borderCircleDelete">
                          <RiDeleteBin6Line size="23" />
                        </div>
                      </div>
                    </div>

                    
                  </div>
                ))}

                <div className="d-flex flex-column flex-md-row mt-3" style={{ paddingTop: "30px", paddingBottom: "100px", float: "right"}}>
                <KButton
                  value="POST"
                  onClick={() => {
                    postQuestionsFunc()
                  }}
                  btnColor={"green"}
                  className={`px-4 py-2 `}
                />
                <KButton
                  onClick={() => {
                    Cancelpostfunc()
                  }}
                  value="CANCEL"
                  className={`px-4 py-2 ${isBigScreen ? "ml-3" : "mt-2"}`}
                  btnColor={"red"}
                />
              </div>
              </div>
              
            )}

            

          </div>
          </>
          }
        </div>
      </div>

      <Footer
        // openForm={openForm}
        // shoq={shoq}
        // closeForm={closeForm}
        // KpostIframe={KpostIframe}
      />
    </div>
  );
};

export default PostAssignment;
