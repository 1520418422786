import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import { CCol } from "@coreui/react";
import TimeTableClass from "./Dashboard_folder/TimeTableClass";
import TimeTableTeacher from "./Dashboard_folder/TimeTableWeekTeacher";
// import { MdVideocam } from "react-icons/md"
import TimeTableCard from "./Dashboard_folder/TimeTableCard";
import {
  getDynamicTimetable,
  getDynamicTimetableByStaff,
} from "../../services/Apiservices";
import Img from "../../Assets/Img.png";
import Img1 from "../../Assets/Img.png";
import { format } from "date-fns";
import Subload from "../../Assets/subject_loading.gif";

const Timetable = ({
  VideoConfFunc,
  tConvert,
  CuttentDateTime,
  tableBgColors,
  openBookview,
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
}) => {
  const navigate = useNavigate();
  const [userCredsvalues, setuserCredsvalues] = useState("");
  const [timeTableData, settimeTableData] = useState([]);
  const [timeTablePeriod, settimeTablePeriod] = useState([]);
  const [singleDateValue, setsingleDateValue] = useState([]);
  const [dateEnable, setdateEnable] = useState("");
  const [selectedDate, setselectedDate] = useState("");
  const [timerData, settimerData] = useState("");
  const [day, setDay] = useState(true);
  const [week, setWeek] = useState(false);
  const [subfetchs, setSubfetchs] = useState(true);
  const Day_Week = () => {
    setDay(true);
    setWeek(false);
  };
  const Week_Day = () => {
    setDay(false);
    setWeek(true);
  };

  // const CuttentDateTime  = new Date().toLocaleString('en-US', { hour12: false,
  //   hour: "numeric",
  //   minute: "numeric"});

  const CurrentDateItem = format(new Date(), "dd/MMM/yyyy");

  const yearMonth = format(new Date(), "dd MMM, yyyy");

  // console.log(format(new Date(),"dd/MMM/yyyy"),"CURRENT DATE");

  useEffect(() => {
    if (localStorage.length > 0) {
      let retrData = JSON.parse(localStorage.userCreds);

      setuserCredsvalues(retrData);

      console.log(retrData);

      const getTimeTablefunc = async () => {
        let allquesbod, allquestres;

        if (retrData.userType === "Teaching") {
          allquesbod = {
            refInstID: retrData.refInstID,
            refStaffID: retrData.staffID,
            refYearID: retrData.yearId,
          };

          allquestres = await getDynamicTimetableByStaff(allquesbod);
        } else {
          allquesbod = {
            refInstID: retrData.refInstID,
            refMediumID: retrData.mediumId,
            refYearID: retrData.yearID,
            refSectionID: retrData.classID,
            refVariableID: retrData.variableID,
          };

          allquestres = await getDynamicTimetable(allquesbod);
        }

        if (allquestres.status === "success") {
          console.log(JSON.parse(allquestres.periodDetails)[0].breakName);
          console.log(
            JSON.parse(allquestres.periodDetails).filter(
              (item) => item.breakName === undefined
            )
          );
          settimeTablePeriod(
            JSON.parse(allquestres.periodDetails).filter(
              (item) => item.breakName === undefined
            )
          );
          settimeTableData(allquestres.value);
          setSubfetchs(false);
          Object.keys(allquestres.value).map((item, i) => {
            if (i === 0) {
              console.log(allquestres.value[item]);
              setsingleDateValue(allquestres.value[item]);
              setselectedDate(item);
              if (CurrentDateItem === item.split(" ")[1]) {
                setdateEnable(0);
              }
            } else {
            }
          });
        }
      };

      getTimeTablefunc();
    } else {
      const goToHomePage = () => navigate("/login");
      goToHomePage();
    }
  }, []);

  console.log(CuttentDateTime, "TABLE COLORS");

  const fetchdateWisetable = async (datevalue, index) => {
    setsingleDateValue(timeTableData[datevalue]);
    setselectedDate(datevalue);
    // setdateEnable(index);

    if (CurrentDateItem === datevalue.split(" ")[1]) {
      setdateEnable(0);
    } else {
      if (index === 0) {
        setdateEnable("");
      } else {
        setdateEnable(index);
      }
    }
  };

  return (
    <div onClick={() => disableOnOuterTouch()}>
      <Header
        showProfile={showProfile}
        // profileIcon={KpIcon}
        // username={state.adminName ? state.adminName : state.user}
        falseShowProfile={falseShowProfile}
        notofShowProfile={notofShowProfile}
        // institutionName={state.institutionName && String(state.institutionName).toUpperCase()}
        ShowNotifications={ShowNotifications}
        showNotification={showNotification}
        falseShowNotification={falseShowNotification}
      />
      {week && (
        <div
          className={"timeTable_Home_scrn"}
          style={
            {
              // display:"none"
            }
          }
        >
          {subfetchs ? (
            <>
              <div
                style={{
                  height: window.innerHeight - 60 + "px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={Subload} alt="" style={{ width: "25%" }} />
              </div>
            </>
          ) : (
            <>
              <CCol md="12">
                <div className={"Padding_Time"}>
                  <div className={"Time_Apr"}>
                    <div>
                      <span className={"Time_Table tableText"}>Timetable</span>
                    </div>

                    <div className={"M_D_W"}>
                      <div>
                        <span className={"Apr_22"}>{yearMonth}</span>
                      </div>

                      <div className={"Switch_Day_Week"}>
                        <div className={"Switch_Day"} onClick={Day_Week}>
                          <span className={"Switch_Day_Font"}>Day</span>
                        </div>
                        <div className={"Switch_Week"}>
                          <span className={"Switch_Week_Font"}>Week</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={"Flex_Row_Period"}>
                    <div className={"Period_Font"}></div>

                    {JSON.parse(localStorage.userCreds).userType ===
                      "Student" &&
                      timeTablePeriod.map((x, i) => (
                        <div className={"Class_Period"}>
                          <span className={"Period_Font"}>
                            Period {x.PeriodNumber}
                          </span>
                          <span className={"Period_Time"}>
                            ({tConvert(x.StartTime)} - {tConvert(x.EndTime)})
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </CCol>

              {JSON.parse(localStorage.userCreds).userType === "Student" &&
                Object.keys(timeTableData).map((x, i) => {
                  return (
                    <>
                      <TimeTableClass
                        day={x.split(" ")[0].slice(0, 3)}
                        subjectdate={x.split(" ")[1]}
                        // subjectdate={yearMonth}

                        subjectData={timeTableData[x]}
                        tConvert={tConvert}
                        peroidList={timeTablePeriod}
                        colorsList={tableBgColors}
                        currentDate={CurrentDateItem}
                        currentTime={CuttentDateTime}
                        JoinMeeting={VideoConfFunc}
                        cursor={"pointer"}
                        currentdateexists={
                          x.split(" ")[1] === CurrentDateItem ? 0 : 1
                        }
                        OpenBook={openBookview}
                        format={format}
                        subject1={"English"}
                        subject2={"Maths"}
                        subject3={"Physics"}
                        subject4={"Botany"}
                        subject5={"Zoology"}
                        subject6={"Tamil"}
                        subject7={"Chemistry"}
                        subject8={"Maths"}
                        bordercolor1={"#9B36EA"} /*purple*/
                        bordercolor2={"#06C270"} /*green*/
                        bordercolor3={"#FC5F37"} /*orange*/
                        bordercolor4={"#3377FF"} /*blue*/
                        bordercolor5={"#3377FF"} /*blue*/
                        bordercolor6={"#FF3E84"} /*red*/
                        bordercolor7={"#F78E13"} /*light-orange*/
                        bordercolor8={"#06C270"} /*green*/
                        iconcolor={"#9B36EA"}
                        color={"#9B36EA"}
                        fontcolor={"#2A004A"}
                        cardcolor={"#FAF4FF"}
                        user1={"Catherin"}
                        image={Img1}
                      />
                    </>
                  );
                })}

              {JSON.parse(localStorage.userCreds).userType === "Teaching" &&
                Object.keys(timeTableData).map((x, i) => {
                  return (
                    <>
                      <TimeTableTeacher
                        day={x.split(" ")[0].slice(0, 3)}
                        subjectdate={x.split(" ")[1]}
                        // subjectdate={yearMonth}

                        subjectData={timeTableData[x]}
                        tConvert={tConvert}
                        peroidList={timeTablePeriod}
                        colorsList={tableBgColors}
                        currentDate={CurrentDateItem}
                        currentTime={CuttentDateTime}
                        JoinMeeting={VideoConfFunc}
                        cursor={"pointer"}
                        currentdateexists={
                          x.split(" ")[1] === CurrentDateItem ? 0 : 1
                        }
                        OpenBook={openBookview}
                        format={format}
                        subject1={"English"}
                        subject2={"Maths"}
                        subject3={"Physics"}
                        subject4={"Botany"}
                        subject5={"Zoology"}
                        subject6={"Tamil"}
                        subject7={"Chemistry"}
                        subject8={"Maths"}
                        bordercolor1={"#9B36EA"} /*purple*/
                        bordercolor2={"#06C270"} /*green*/
                        bordercolor3={"#FC5F37"} /*orange*/
                        bordercolor4={"#3377FF"} /*blue*/
                        bordercolor5={"#3377FF"} /*blue*/
                        bordercolor6={"#FF3E84"} /*red*/
                        bordercolor7={"#F78E13"} /*light-orange*/
                        bordercolor8={"#06C270"} /*green*/
                        iconcolor={"#9B36EA"}
                        color={"#9B36EA"}
                        fontcolor={"#2A004A"}
                        cardcolor={"#FAF4FF"}
                        user1={"Catherin"}
                        image={Img1}
                      />
                    </>
                  );
                })}
            </>
          )}
        </div>
      )}

      {day && (
        <div>
          <CCol md="12">
            <div
              className={"timeTable_Home_scrn"}
              style={
                {
                  // display:"none"
                }
              }
            >
              {subfetchs ? (
                <>
                  <div
                    style={{
                      height: window.innerHeight - 60 + "px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={Subload} alt="" style={{ width: "25%" }} />
                  </div>
                </>
              ) : (
                <>
                  <div className={"Padding_Time"}>
                    <div className={"Time_Apr"}>
                      <div>
                        <span className={"Time_Table tableText"}>
                          Timetable
                        </span>
                      </div>

                      <div className={"F_Gap"}>
                        <div>
                          <span className={"Apr_22"}>{yearMonth}</span>
                        </div>

                        <div className={"Switch_Day_Week"}>
                          <div className={"Switch_Day_Blue"}>
                            <span className={"Switch_Day_Font_Timetable"}>
                              {" "}
                              Day{" "}
                            </span>
                          </div>
                          <div
                            className={"Switch_Week_White"}
                            onClick={Week_Day}
                          >
                            <span className={"Switch_Week_Font_Timetable"}>
                              {" "}
                              Week{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={"Day_Date tableVTxt"}>
                    <div className={"D_Flex"}>
                      {Object.keys(timeTableData).map((item, i) => (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            fetchdateWisetable(item, i);
                          }}
                        >
                          {console.log(timeTableData)}
                          <p
                            className={
                              dateEnable === i
                                ? "Tue_Day"
                                : dateEnable === "" && i === 0
                                ? "Tue_Day"
                                : "Day"
                            }
                          >
                            {item.split(" ")[0].slice(0, 3)}
                          </p>
                          <h5
                            className={
                              dateEnable === i
                                ? "Tue_Date"
                                : dateEnable === "" && i === 0
                                ? "Tue_Date"
                                : "Date"
                            }
                          >
                            {item.split(" ")[1].split("/")[0]}
                          </h5>
                        </div>
                      ))}
                    </div>
                  </div>

                  {singleDateValue.map((x, i) => {
                    return (
                      <>
                        <TimeTableCard
                          scheduleList={x}
                          periodList={timeTablePeriod.filter(
                            (q) => String(q.PeriodNumber) === String(x.period)
                          )}
                          userimage={Img}
                          bordercolor={tableBgColors[i]}
                          lesson={"#66747F"}
                          JoinMeeting={VideoConfFunc}
                          cursor={"pointer"}
                          tConvert={tConvert}
                          currentTime={CuttentDateTime}
                          dateEnable={dateEnable}
                          OpenBook={openBookview}
                          TableDate={selectedDate}
                        />
                      </>
                    );
                  })}
                </>
              )}
            </div>
          </CCol>
        </div>
      )}

      <Footer
        openForm={openForm}
        shoq={shoq}
        closeForm={closeForm}
        KpostIframe={KpostIframe}
      />
    </div>
  );
};

export default Timetable;
