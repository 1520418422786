import React,{useState,useEffect} from 'react'
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import {getDynamicSubjects,getAllKatBookSubjectsCorporate,getAllKatBookSubjectsCount,fetchSUbjects} from "../../services/Apiservices"
import { useLocation, useNavigate } from 'react-router-dom';
import KatbookBooks from "./KatbookBooks";
import Subload from "../../Assets/subject_loading.gif";


const BookView = ({showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm
}) => {
    const [Units, setUnits] = useState([]);
    const [userCredsvalues, setuserCredsvalues] = useState('');
    const [subfetchs, setSubfetchs] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
      document.title = "ISKCON BOOK VIEW";
        if(localStorage.length > 0){
    
          let retrData = JSON.parse(localStorage.userCreds);
    
          setuserCredsvalues(retrData);

          const getfetchbooks = async () => {
          
            let allquestres,filteredCourses;
    
            allquestres =await  fetchSUbjects();

            console.log(allquestres, "SDFAAF");
    
            if(allquestres.success === true){

              console.log(retrData.user.userTypeId.userType,"SDFAAF");
              if(retrData.user.userTypeId.userType === "STUDENT"){
                filteredCourses = allquestres.units.filter((items) => String(items.age) === String(retrData.user.ageGroup));
              } else {
                filteredCourses = allquestres.units;
              }
              
              console.log(allquestres,"FILTERED COURSE");
              console.log(filteredCourses,"FILTERED COURSE");
              console.log(retrData.user.age,"FILTERED COURSE");
              
              // console.log(filteredCourses,"FILTERED COURSE");
            let codeSessArr1 = [];

            for (let d = 0; d < filteredCourses.length; d++) {
                const e = filteredCourses[d]["KATBOOK_SUBJECT_ID"];
                console.log(e, "SDFAAF");

                codeSessArr1.push({
                  Books: filteredCourses[d],
                });
      
                // if (e !== "" || e !== null || e !== "null") {
                //   let stufetchsubBooks = {
                //     session: e,
                //   };
      
                //   // console.log(bookSession,"getAllBookbyStudentSSSSSSSSSSS");
                //   let studentS_book_response = await getAllKatBookSubjectsCorporate(
                //     stufetchsubBooks
                //   );

                  // if(studentS_book_response.success === true){
                    
                  //   let stufetchsubBooksIdd = {
                  //       bookId: studentS_book_response.units[0].bookId,
                  //     };

                  //   let studentS_book_response_Unit = await getAllKatBookSubjectsCount(
                  //       stufetchsubBooksIdd
                  //     );

                  //     codeSessArr1.push({
                  //       Books: studentS_book_response.units[0],
                  //       subjectID: allquestres.value[d]["SUBJECT_ID"],
                  //       unit: studentS_book_response_Unit.unit,
                  //       session: studentS_book_response_Unit.session,
                  //       teacherName: allquestres.value[d]["FIRST_NAME"]
                  //     });

                  // }
      
                //   console.log(studentS_book_response_Unit, "TBOOKSLIST");
                  // codeSessArr.push(studentS_book_response.units[0]);
                // }

                // setUnits(...Units, codeSessArr1);
                if (filteredCourses.length - 1 === d) {
                  setUnits(codeSessArr1);
                  setSubfetchs(false);
                  console.log(Units, "TBOOKSLIST");
                  console.log(codeSessArr1, "TBOOKSLIST");
                  // console.log("valueeeeeeeeeeeeeeeeeeeeeeeeeee", response.units);
                }
          
                }

            }

        }


        const getopenwindow = async () => {

          let kat = {
            logname: retrData.userName,
            logpassword: retrData.password,
            institutionName: retrData.institutionName
          }
          

          // if(isDesktopOrLaptop || isBigScreen){
          //   // alert(1);
          //   var newWindow = window.open(
          //     `https://s3.katbook.com/#/student/bookList/${btoa(JSON.stringify(kat))}`,'myWindow', 'width=,height=,resizable=no', '',"disabled"
          //   );
          //   newWindow.resizeTo(0,0); 
          //   newWindow.moveTo(0,newWindow.screen.availHeight+10);
          //   newWindow.blur();
          //   window.focus();
          //   setTimeout(() => newWindow.close(), 1000);
          // } else {
          //   // alert(2);
          //   var newWindow = window.open(
          //     `https://s3.katbook.com/#/student/bookList/${btoa(JSON.stringify(kat))}`,'myWindow', 'location=no,toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000,top=10000, width=1,height=1,visible=none', '',"disabled"
          //   );
          //   setTimeout(() => newWindow.close(), 1000);
          // }
          
          
          getfetchbooks();

        }

        getopenwindow();
      
        } else {
          const goToHomePage = () => navigate("/login");
          goToHomePage();
        }
        
      },[]);

  return (
    <div
    onClick = {()=>disableOnOuterTouch()}
    >
        <div className="Navbar">
        <Header 
        showProfile={showProfile}
        // profileIcon={KpIcon}
        // username={state.adminName ? state.adminName : state.user}
        falseShowProfile={falseShowProfile}
        notofShowProfile={notofShowProfile}
        // institutionName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType == 'ADMIN' ? JSON.parse(localStorage.userCreds).user.institutionName.toUpperCase() : JSON.parse(localStorage.userCreds).user.institutionId.institutionName.toUpperCase() : ""}
        personName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType == 'ADMIN' ? JSON.parse(localStorage.userCreds).user.authorizedPerson : JSON.parse(localStorage.userCreds).user.fullName : ""}
        ShowNotifications={ShowNotifications}
        userName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userName : ""}
        showNotification={showNotification}
        falseShowNotification={falseShowNotification}
        userType = {localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType : ""}
        userData = {localStorage.length > 0 ? JSON.parse(localStorage.userCreds) : ""}
      />
        </div>
        <div className={"Dash_home_QWI"}>
        {subfetchs ? (
            <>
              <div
                style={{
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={Subload} alt="" style={{ width: "25%" }} />
              </div>
            </>
          ) : (
        <div className={"Subject_container"}
        style={{
            height: "auto",
            overflowY: "auto"
        }}
        >
            <div className={"H_katbook"}>
            <h4 className={"Margin_Left_Card"}>Books</h4>
            </div>

            <div className={"Container marginBottom-20"}>
            {Units.map((x, i) => {
                return <KatbookBooks count={i} key={x._id} x={x} usercreds={userCredsvalues} />;
            })}
            </div>
        </div>
          )
        }


        <Footer 
          openForm={openForm}
          shoq={shoq}
          closeForm={closeForm}
          KpostIframe={KpostIframe}
        />

        
        </div>
          
      
    </div>
  )
};

export default BookView