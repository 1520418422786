import React, {useState} from "react";
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import { useMediaQuery } from "react-responsive";
import useResponsive from "../../hooks/useResponsive";
import KHeader from "./common/KHeader";
import KButton from "./common/KButton";
import KDropdown from "./common/KDropdown";
import KInput from "./common/KInput";
import data from "../../../src/data.json";
import { AiOutlineLeft } from "react-icons/ai";

const ViewAssignment = ({
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  frontElement
}) => {
  const { isBigScreen } = useResponsive();
  const [showButton, setShowButton] = React.useState(false);
  const [showSubject, setShowSubject] = React.useState(false);
  const [showPosted, setShowPosted] = React.useState(false);
  const [subjectDetail, setSubjectDetail] = useState(false);

  const [num, setNum] = useState('');
  const [num1, setNum1] = useState('');


  const [categoryView,setCategoryView] = useState("");

  const categoryViewList = [
    "draft",
    "posted",
    "review"
  ]


  const handleNumChange = event => {
    const limit = 3;
    setNum(event.target.value
      // .slice(0, limit)
      );
  };

  // const handleNumChange1 = event => {
  //   const limit = 3;
  //   setNum1(event.target.value
  //     // .slice(0, limit)
  //     );
  // };

  const subject = [
    {
      Class: "XII - A - Physics",
      Lesson: "Electrostatistics",
      Date: "Posted on: 15.03.2021",
      LastDate: "Last Date of Submission: 22.03.2021",
    },
    {
      Class: "XII - B - Physics",
      Lesson: "Current Electricity",
      Date: "Posted on: 16.03.2021",
      LastDate: "Last Date of Submission: 24.03.2021",
    },
    {
      Class: "XI - C - Physics",
      Lesson: "Units and Measurement",
      Date: "Posted on: 15.03.2021",
      LastDate: "Last Date of Submission: 23.03.2021",
    },
    {
      Class: "XI - A - Physics",
      Lesson: "Systems of Particles and Rotational Motion",
      Date: "Posted on: 15.03.2021",
      LastDate: "Last Date of Submission: 23.03.2021",
    },
  ];

  return (
    <div onClick={() => disableOnOuterTouch()}>
      <div className="Navbar">
        <Header 
        showProfile={showProfile}
        // profileIcon={KpIcon}
        // username={state.adminName ? state.adminName : state.user}
        falseShowProfile={falseShowProfile}
        notofShowProfile={notofShowProfile}
        // institutionName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType == 'ADMIN' ? JSON.parse(localStorage.userCreds).user.institutionName.toUpperCase() : JSON.parse(localStorage.userCreds).user.institutionId.institutionName.toUpperCase() : ""}
        personName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType == 'ADMIN' ? JSON.parse(localStorage.userCreds).user.authorizedPerson : JSON.parse(localStorage.userCreds).user.fullName : ""}
        ShowNotifications={ShowNotifications}
        userName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userName : ""}
        showNotification={showNotification}
        falseShowNotification={falseShowNotification}
        userType = {localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType : ""}
      />
      </div>
      <div className={"Dash_home_QWI"}>
        <div
          className="myattendcard2w p-3"
          style={{
            height: window.innerHeight - 117 + "px",
            overflowY: "auto",
          }}
        >
          <KHeader title="View Exam" containerClassName={`marginTop-20`} />
          <div className="container-fluid px-5 py-3">
            <div className="d-flex flex-column flex-md-row">
              <KButton
                value="Draft Assignments"
                className={`px-6 py-3 mr-3  ${isBigScreen ? "w-25" : "w-100"}`}
                onClick={()=>{setCategoryView(categoryViewList[0])}}
              />

              <KButton
                value="Posted Assignments"
                onClick={() => {
                  setCategoryView(categoryViewList[1])
                  setShowButton(!showButton);
                  setShowSubject(false);
                }}
                className={`px-6 py-3 mr-3 ${
                  isBigScreen ? "w-25" : "w-100 mt-2"
                }`}
                // onClick = {() =>{showNewQuestionBank()}}
              />

              <KButton
                value="Review Assignments"
                className={`px-6 py-3 ${isBigScreen ? "w-25" : "w-100 mt-2"}`}
                onClick={() => {
                  setCategoryView(categoryViewList[2])
                  // setShowSubject(!showSubject);
                  setShowPosted(false);
                  setSubjectDetail(false);
                }}
              />
            </div>

            <div className="container-fluid px-0 py-3">
              {showButton &&categoryView === categoryViewList[1] && (
                <div className="d-flex flex-column flex-md-row gap-2">
                  <KButton
                    value="Today"
                    onClick={() => {
                      setShowSubject(!showSubject);
                      setShowPosted(false);
                    }}
                    className={`px-3 py-2  W-15 ${
                      isBigScreen ? "W-15" : "w-50"
                    }`}
                  />
                  {/* ${isBigScreen ? "w-25" : "w-100"} */}

                  <KButton
                    value="Week"
                    className={`px-3 py-2  W-15 ${
                      isBigScreen ? "W-15" : "w-50"
                    }`}
                  />
                  <KButton
                    value="Month"
                    className={`px-3 py-2  W-15 ${
                      isBigScreen ? "W-15" : "w-50"
                    }`}
                  />
                </div>
              )}
            </div>

            <div className="container-fluid px-5 py-3 pt-0">
              {showSubject && (
                <div className="d-flex flex-column flex-md-row gap-5 pt-5">
                  {subject.map((items) => (
                    <div
                      className="SubjectStyle"
                      onClick={() => {
                        if(categoryView === categoryViewList[2]){
                          setShowPosted(false);
                          setSubjectDetail(true);
                          setShowSubject(false);
                        } else{
                          setShowPosted(true);
                          setShowSubject(false);
                          
                        }
                       
                      }}
                    >
                      <div>
                        <h4>{items.Class}</h4>
                        <p style={{ fontWeight: "600", fontSize: "17px" }}>
                          {items.Lesson}
                        </p>
                      </div>

                      <div>
                        <span>{items.Date}</span>
                        <p>{items.LastDate}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {showPosted && (
              <div className="pt-0">
                <div>
                  <KHeader title="Posted Assignments" containerClassName={``} />
                </div>

                <div className="container-fluid px-5 py-3">
                  <div className="d-flex flex-column flex-md-row">
                    <KDropdown
                      containerClassName="w-100"
                      label="Select Class"
                      isRequired={true}
                      placeholder="Select Class"
                    />
                    <KInput
                      label="Assignment Name"
                      isRequired={true}
                      placeholder="Enter Assignment Name"
                      width={"300px"}
                      containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                    />
                    <KInput
                      label="Total Marks"
                      isRequired={true}
                      placeholder="Enter Total Marks"
                      containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 ${
                        isBigScreen ? "w-50" : "w-100"
                      }`}
                      labelClassName={"width-maxContent"}
                      inputClassName="w-100"
                      type="number"
                      // id="num"
                      // name="num"
                      // value={num}
                      // onChange={handleNumChange}
                    />
                    <KInput
                      label="Last Date of Submission"
                      containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                      isRequired={true}
                      type="date"
                      min="1997-01-01"
                      max="2030-12-31"
                    />
                  </div>
                </div>

                <div className="d-flex flex-row justify-content-between align-items-center pb-3">
                  <KHeader
                    title="Questions & Answers"
                    style={{
                      marginLeft: "0px !important",
                    }}
                  />
                </div>

                <div>
                  {data.map((items) => (
                    <div className={`${isBigScreen ? "px-5 my-3 " : "w-100"}`}>
                      <div className="d-flex flex-row gap-3 align-items-center w-100">
                        <div
                          className={`DivBox ${
                            isBigScreen ? "col-8" : "col-9"
                          }`}
                        >
                          {/* Question 1 */}
                          {items.Que}
                        </div>

                        <KInput
                          placeholder="Enter Marks"
                          type="number"
                          className={`DivBox ${isBigScreen ? "w-75" : "w-100"}`}
                        />
                      </div>

                      <div
                        className={`DivBoxBig mt-3 mb-5 ${
                          isBigScreen ? "col-8" : "w-75 ml-0"
                        }`}
                      >
                        {/* Answer 1 */}
                        {items.Ans}
                      </div>
                    </div>
                  ))}

                  <div className="d-flex flex-column justify-content-end flex-md-row pr-5">
                    <KButton
                      value="Back"
                      onClick={() => {
                        setShowPosted(false);
                        setShowSubject(true);
                      }}
                      btnColor={"red"}
                      className={`px-3 py-1 ButtonSize`}
                    />
                  </div>
                </div>
              </div>
            )}

              {subjectDetail && (                
                <KHeader 
                  containerClassName={"align-items-center col-8 borderColor marginLeft-0"}
                  frontElement={
                  <div className="" style={{display:"flex",flexDirection:"row",gap:"35px"}}>
                    <div>
                      <AiOutlineLeft size="25" style={{cursor:"pointer"}}
                        onClick={()=>{
                        setSubjectDetail(!subjectDetail);
                        setShowSubject(true);
                        }}
                      />
                    </div>
                    <div>
                      <h4>
                        XII - A - Physics
                      </h4>
                      <h4>
                        Electrostatistics
                      </h4>
                    </div>
                  </div>
                }

                LastElement={
                  <div className="" style={{display:"flex",gap:"20px",alignItems:"center"}}>
                      <div style={{display:"flex",flexDirection:"column",gap:"15px"}}>
                        <span>Posted on : 15-03-2021</span>
                        <span>Last Date of Submission : 22-03-2021</span>
                      </div>
                      <div>
                        <KButton
                          value="Submit"
                          className={`px-3 py-2`}
                          btnColor="green"
                        />
                      </div>
                  </div>
                }              
               />
              )}
    



          </div>
        </div>
      </div>

      <Footer
        // openForm={openForm}
        // shoq={shoq}
        // closeForm={closeForm}
        // KpostIframe={KpostIframe}
      />
    </div>
  );
};

export default ViewAssignment;
