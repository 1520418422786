import React, { useState, useRef } from "react";
import clock from "../../Assets/clock.png";

const Timer = ({ timer, settingTimer, countRef, alert }) => {
  React.useEffect(() => {
    countRef.current = setInterval(() => {
      settingTimer();
    }, 1000);
    return () => clearInterval(countRef.current);
  }, []);

  const formatTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

  return (
    <div className={"d-flex flex-row align-items-center mr-3"}>
      <div className={"d-flex flex-row align-items-center"}>
        <img
          style={{
            marginTop: "2px",
            width: "20px",
            height: "20px",
          }}
          src={clock}
          alt="timer"
        />
        <div
          style={{
            color: alert && timer < 10 ? "red" : "",
          }}
          className="paddingLeft-5 clock_timer align-items-center"
        >
          {formatTime(timer)}
        </div>
      </div>
    </div>
  );
};

export default Timer;
