import react from 'react';
import { menuItems } from './menuItems';
import { menuItemsAdmin } from './menuItemsAdmin';
import MenuItems from './MenuDropdownItems';
const Navbar = ({userData}) => {
  console.log(userData,"ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ");
  return (
    <nav className={"SubMenu"}>
      <ul className="menus">
      {userData.user.userTypeId.userType === 'STUDENT' && menuItems.map((menu, index) => {
    const depthLevel = 0;
    return <MenuItems items={menu} key={index} depthLevel={depthLevel} userData={userData} />;
   })}
   {userData.user.userTypeId.userType === 'ADMIN' && menuItemsAdmin.map((menu, index) => {
    const depthLevel = 0;
    return <MenuItems items={menu} key={index} depthLevel={depthLevel} userData={userData} />;
   })}
      </ul>
    </nav>
  );
};

export default Navbar;