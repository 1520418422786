import React from "react";
import "./KInput.css";

function KInput({
  containerStyle,
  error,
  inputStyle,
  inputClassName,
  labelStyle,
  labelClassName,
  containerClassName,
  label,
  isRequired,
  onChange,
  value,
  options,
  isDisabled,
  readonly,
  placeholder,
  autoFocus,
  type,
  Warning,
  toast,
  ...props
}) {
  return (
    <div
      style={{ ...containerStyle }}
      className={`d-flex flex-column ${containerClassName} `}
    >
      {label !== "" && (
        <label
          className={`h6 width-fit ${labelClassName} ${
            isRequired ? "required-field" : ""
          }`}
          style={{ ...labelStyle }}
        >
          {label}
        </label>
      )}
      <input
        className={`w-100 KInput__input ${inputClassName}`}
        style={{ ...inputStyle }}
        placeholder={placeholder}
        autoFocus={autoFocus}
        type={type}
        onChange={(event) => { 
          // alert(type)
          if(type === 'number'){
            const re = /^[0-9\b]+$/;
            // alert(re.test(event.target.value))
              if (event.target.value === '' || re.test(event.target.value)) {
                onChange(event.target.value)
              } else {
                toast.error(<Warning body={"Please enter numbers only"} />)
                onChange('')
              }
          } else {
            onChange(event.target.value)
          }
          
          }}
        value={value}
        // type={type}
        isDisabled={isDisabled}
        readOnly={readonly}
        {...props}
      />
      {error !== "" && <div className="text-danger">{error && error}</div>}
    </div>
  );
}

KInput.defaultProps = {
  isRequired: false,
  label: "",
  value: null,
  onChange: () => {},
  options: [],
  autoFocus: false,
  placeholder: "",
  isDisabled: false,
  readonly: false,
  labelClassName: "",
  labelStyle: {},
  inputClassName: "",
  inputStyle: {},
  error: "",
  containerClassName: "",
  type: "text",
  toast: "",
  Warning: ""
};

export default KInput;
