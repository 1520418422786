const getUserType = () => {
  const userType = JSON.parse(localStorage.getItem('userCreds'))?.user.userTypeId.userType
    if(userType){
      return userType;
    }
  return;

}

// console.log(JSON.parse(localStorage.getItem('userCreds'))?.user.userTypeId.userType,"USER TYPE NAME");
const subMenuAssessment =  String(JSON.parse(localStorage.getItem('userCreds'))?.user.userTypeId.userType) === String("STUDENT") ? ""
// [
//   {
//     title: 'Past Assignment',
//     url: '../assignment/past',
//   },
//   {
//       title: 'Exams',
//       url: '../exam/student',
//     },
//     {
//       title: 'Pending Assignment',
//       url: '../assignment/pending',
//     },


//     {
//       title: 'Create',
//       url: '../assignment/create',
//     },
//     {
//         title: 'View',
//         url: '../assignment/view',
//       },
//       {
//         title: 'Post',
//         url: '../assignment/post',
//       },
// ] 
:
[
    {
      title: 'Create',
      url: '../assignment/create',
    },
    {
        title: 'View',
        url: '../assignment/view',
      },
      {
        title: 'Post',
        url: '../assignment/post',
      },



  ]



export const menuItems = [
    {
      title: 'Dashboard',
      url: '/student/dashboard',
    },
    {
        title: 'Katbook',
        url: '/BookView',
      },   
    {
        title : 'Exam',
        url :'/exam/student',
    },
  ];