import { Navigate } from 'react-router-dom';

const Home = () => {
    return (
      localStorage && localStorage?.length > 0 && localStorage.userCreds !==  undefined && JSON.parse(localStorage.userCreds) && JSON.parse(localStorage.userCreds).user.userTypeId.userType === 'ADMIN' ? (
        <Navigate to="/dashboard" />
      ): localStorage && localStorage?.length > 0 && localStorage.userCreds !==  undefined && JSON.parse(localStorage.userCreds) && JSON.parse(localStorage.userCreds).user.userTypeId.userType === 'STUDENT' ? (
        <Navigate to="/exam/student" />
      ): (
        <Navigate to="/login" />
      )
      
    );
};


export default Home;