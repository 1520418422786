import React, { useEffect, useState, useRef } from "react";
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import { CCol } from "@coreui/react";
import Img from "../../Assets/Img.png";
import TimeTableCard from "./Dashboard_folder/TimeTableCard";
import { RiArrowDropUpLine, RiArrowDropDownLine } from "react-icons/ri";
// import ExamSchedule from "./Dashboard_folder/ExamSchedule";
// import Assigments from "./Dashboard_folder/Assigments";
import Example from "./Dashboard_folder/Example";
import MyCarousel from "./Dashboard_folder/MyCarousel";
import Event from "./Dashboard_folder/Event";
import Exam_Result from "./Dashboard_folder/Exam_Result";
import Birthday from "./Dashboard_folder/Birthday";
import ksmaclogo from "../../Assets/ksmaclogo.png";
import KatbookLogo from "../../Assets/KatbookLogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getDynamicTimetable,
  getDynamicTimetableByStaff,
} from "../../services/Apiservices";
import { format } from "date-fns";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import kpost from "../../Assets/ksmaclogo.png";
import moment from "moment";
import { Authcontext } from "./Context/Context";
import ClarifySvgIcon from "./ClarifySvgIcon";
// For ISCON Dashboard
import { TbChartPie2 } from "react-icons/tb";
import { BiLineChart, BiSearch, BiChevronRight } from "react-icons/bi";
import { Bar, Line, Pie } from "react-chartjs-2";
import { BsListCheck, BsArrowLeftShort } from "react-icons/bs";
import data from "../../../src/data.json";

import IskonLogo from "../../Assets/Iskon_Img.jpg";
import IskonLapLogo from "../../Assets/Iskon_Lap_Img.png";
import { Player } from "video-react";
import StLogo from "../../Assets/student_pic.png";
import useResponsive from "../../hooks/useResponsive";

import {
  GetAllProgressByStudent,
  GetSessionCountbySubject,
  fetchSUbjects,
  GetAssessmentAnswerByStudent,
  GetQuestionCountbySubject,
  GetlastSessionByStudent,
} from "../../services/Apiservices";

const Dashboard = ({
  VideoConfFunc,
  tConvert,
  CuttentDateTime,
  tableBgColors,
  openBookview,
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  trueShowNotification,
}) => {
  const navigate = useNavigate();

  const [userCredsvalues, setuserCredsvalues] = useState("");
  const [ProgressData, setProgressData] = useState([]);
  const [UserSubject, setUserSubject] = useState("");
  const [Booksessioncount, setBooksessioncount] = useState("");
  const [Bookquestioncount, setBookquestioncount] = useState("");
  const [Progresssessioncount, setProgresssessioncount] = useState("");
  const [Progressquestioncount, setProgressquestioncount] = useState("");
  const [UserLastSession, setUserLastSession] = useState("");
  // For ISCON Dashboard
  const { isBigScreen, isTabScreen } = useResponsive();
  const sessionEnableRef = useRef();

  // const { dispatch}= React.useContext(Authcontext);

  const getStudentEnablesessionData = async (userData) => {
    let getUserlastSession = await GetlastSessionByStudent(userData.user._id);

    if (getUserlastSession.success === true) {
      setUserLastSession(getUserlastSession.data);
    } else {
      setUserLastSession();
    }
  };

  const getStudentProgressData = async (userData) => {
    let res_progress = await GetAllProgressByStudent(userData.user._id);

    if (res_progress.success) {
      setProgressData(res_progress.data);
      const firstPerValues = res_progress.data.filter(
        (items) => Number(items.percentage) === 100
      ).length;
      const secondPervalues =
        res_progress.data.filter((items) => Number(items.percentage) === 50)
          .length / 2;
      setProgresssessioncount(Number(firstPerValues) + Number(secondPervalues));
    }

    let allquestres = await fetchSUbjects();

    if (allquestres.success === true) {
      const filteredCourses = allquestres.units.filter(
        (items) => String(items.age) === String(userData.user.ageGroup)
      );
      setUserSubject(filteredCourses[0]);
      console.log(filteredCourses[0], "WWWWWWWWWWWWWWWWWWWWWWWWWW");
      let res_session_count = await GetSessionCountbySubject(
        filteredCourses[0]["bookId"]
      );

      if (res_session_count.success === true) {
        setBooksessioncount(res_session_count.sessionCount);
      } else {
        setBooksessioncount(0);
      }

      let res_question_count = await GetQuestionCountbySubject(
        filteredCourses[0]["bookId"]
      );

      if (res_question_count.success === true) {
        setBookquestioncount(res_question_count.questionCount);
      } else {
        setBookquestioncount(0);
      }

      let allStuAnsReq = await GetAssessmentAnswerByStudent(userData.user._id);

      if (allStuAnsReq.success === true) {
        setProgressquestioncount(allStuAnsReq.value.length);
      } else {
        setProgressquestioncount(0);
      }
    }
  };

  useEffect(() => {
    console.log(localStorage, "PPPPPPPPPPPPPPPPPPPPP");
    if (localStorage.length > 0) {
      let retrData = JSON.parse(localStorage.userCreds);

      setuserCredsvalues(retrData);

      if (retrData.user.userTypeId.userType === "ADMIN") {
        const goToHomePage = () => navigate("/dashboard");
        goToHomePage();
      } else {
        console.log(retrData, "PPPPPPPPPPPPPPPPPPPPP");

        getStudentEnablesessionData(retrData);

        getStudentProgressData(retrData);
      }
    } else {
      // alert();
      const goToHomePage = () => navigate("/login");
      goToHomePage();
    }
  }, []);
  useEffect(() => {
    userCredsvalues &&
      setInterval(() => {
        getStudentEnablesessionData(userCredsvalues);
        getStudentProgressData(userCredsvalues);
      }, 3000);
  }, [userCredsvalues]);

  // React.useEffect(() => {
  //   sessionEnableRef.current = setInterval(() => {

  //     const getEnabelFuncByStudent = async() => {
  //       let getUserlastSession = await GetlastSessionByStudent(userCredsvalues.user._id);

  //       if(getUserlastSession.success === true){
  //         setUserLastSession(getUserlastSession.data)
  //       } else {
  //         setUserLastSession()
  //       }

  //     }

  //     getEnabelFuncByStudent();

  //   }, 1000)
  //      return () => clearInterval(sessionEnableRef.current);
  // },[])

  console.log(CuttentDateTime, "TABLE COLORS");

  const instDetail = localStorage.getItem("instDetail");
  const userInstDetail = JSON.parse(instDetail);

  // Header Functions End
  return (
    <div
      // style={{overflow: 'hidden'}}
      onClick={() => disableOnOuterTouch()}
    >
      <Header
        showProfile={showProfile}
        // profileIcon={KpIcon}
        // username={state.adminName ? state.adminName : state.user}
        falseShowProfile={falseShowProfile}
        notofShowProfile={notofShowProfile}
        // institutionName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType == 'ADMIN' ? JSON.parse(localStorage.userCreds).user.institutionName.toUpperCase() : JSON.parse(localStorage.userCreds).user.institutionId.institutionName.toUpperCase() : ""}
        personName={
          localStorage.length > 0
            ? JSON.parse(localStorage.userCreds).user.userTypeId.userType ==
              "ADMIN"
              ? JSON.parse(localStorage.userCreds).user.authorizedPerson
              : JSON.parse(localStorage.userCreds).user.fullName
            : ""
        }
        ShowNotifications={ShowNotifications}
        userName={
          localStorage.length > 0
            ? JSON.parse(localStorage.userCreds).user.userName
            : ""
        }
        showNotification={showNotification}
        falseShowNotification={falseShowNotification}
        trueShowNotification={trueShowNotification}
        userType={
          localStorage.length > 0
            ? JSON.parse(localStorage.userCreds).user.userTypeId.userType
            : ""
        }
        userData={
          localStorage.length > 0 ? JSON.parse(localStorage.userCreds) : ""
        }
      />

      <div className={"Dash_home_QWI_std"}>
        <div className="col-md-12" style={{ marginTop: "-15px" }}>
          <img src={IskonLogo} width="100%" style={{ borderRadius: "10px" }} />
        </div>

        <div
          // className="col-md-12 d-flex flex-row w-100"
          className={`col-md-12 d-flex flex-row w-100 ${
            isBigScreen ? "flex-row" : "flex-column gap-4"
          }`}
          style={{ marginTop: "30px", marginBottom: "10px" }}
        >
          <div className="col-md-3 SessionView">
            <div className="d-flex flex-column gap-2 align-items-center text-align-center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (UserLastSession) {
                      window.open(
                        `https://iskconsamskriti.katbook.com/#/kampus/BookView/${
                          UserSubject.path.split("{")[0]
                        }{"sessionCode":"${UserLastSession.katSessionCode}",${
                          UserSubject.path.split("{")[1]
                        }{"_id":"${UserLastSession.katUnitID}","username":"${
                          userCredsvalues.user.userName
                        }","code":"${userCredsvalues.user.password}"}}/1/${
                          UserSubject.bookId
                        }/1/1`
                      );
                    } else {
                      window.open(
                        `https://iskconsamskriti.katbook.com/#/kampus/BookView/${
                          UserSubject.path.split('"parentVaribaleId"')[0]
                        }"parentVaribaleId":{"username":"${
                          userCredsvalues.user.userName
                        }","code":"${userCredsvalues.user.password}"}}/1/${
                          UserSubject.bookId
                        }/1/1`
                      );
                    }

                    //   window.close();
                  }}
                  onTouchStart={() => {
                    if (UserLastSession) {
                      window.open(
                        `https://iskconsamskriti.katbook.com/#/kampus/BookView/${
                          UserSubject.path.split("{")[0]
                        }{"sessionCode":"${UserLastSession.katSessionCode}",${
                          UserSubject.path.split("{")[1]
                        }{"_id":"${UserLastSession.katUnitID}","username":"${
                          userCredsvalues.user.userName
                        }","code":"${userCredsvalues.user.password}"}}/1/${
                          UserSubject.bookId
                        }/1/1`
                      );
                    } else {
                      window.open(
                        `https://iskconsamskriti.katbook.com/#/kampus/BookView/${
                          UserSubject.path.split('"parentVaribaleId"')[0]
                        }"parentVaribaleId":{"username":"${
                          userCredsvalues.user.userName
                        }","code":"${userCredsvalues.user.password}"}}/1/${
                          UserSubject.bookId
                        }/1/1`
                      );
                    }

                    //   window.close();
                  }}
                  src={UserSubject.coverImage}
                  className="IskonLogoLap"
                />
              </div>
              <div
                onClick={() => {
                  if (UserLastSession) {
                    window.open(
                      `https://iskconsamskriti.katbook.com/#/kampus/BookView/${
                        UserSubject.path.split("{")[0]
                      }{"sessionCode":"${UserLastSession.katSessionCode}",${
                        UserSubject.path.split("{")[1]
                      }{"_id":"${UserLastSession.katUnitID}","username":"${
                        userCredsvalues.user.userName
                      }","code":"${userCredsvalues.user.password}"}}/1/${
                        UserSubject.bookId
                      }/1/1`
                    );
                  } else {
                    window.open(
                      `https://iskconsamskriti.katbook.com/#/kampus/BookView/${
                        UserSubject.path.split('"parentVaribaleId"')[0]
                      }"parentVaribaleId":{"username":"${
                        userCredsvalues.user.userName
                      }","code":"${userCredsvalues.user.password}"}}/1/${
                        UserSubject.bookId
                      }/1/1`
                    );
                  }

                  //   window.close();
                }}
                onTouchStart={() => {
                  if (UserLastSession) {
                    window.open(
                      `https://iskconsamskriti.katbook.com/#/kampus/BookView/${
                        UserSubject.path.split("{")[0]
                      }{"sessionCode":"${UserLastSession.katSessionCode}",${
                        UserSubject.path.split("{")[1]
                      }{"_id":"${UserLastSession.katUnitID}","username":"${
                        userCredsvalues.user.userName
                      }","code":"${userCredsvalues.user.password}"}}/1/${
                        UserSubject.bookId
                      }/1/1`
                    );
                  } else {
                    window.open(
                      `https://iskconsamskriti.katbook.com/#/kampus/BookView/${
                        UserSubject.path.split('"parentVaribaleId"')[0]
                      }"parentVaribaleId":{"username":"${
                        userCredsvalues.user.userName
                      }","code":"${userCredsvalues.user.password}"}}/1/${
                        UserSubject.bookId
                      }/1/1`
                    );
                  }

                  //   window.close();
                }}
              >
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  {UserSubject.book}
                </span>
              </div>
              <div
                onClick={() => {
                  if (UserLastSession) {
                    window.open(
                      `https://iskconsamskriti.katbook.com/#/kampus/BookView/${
                        UserSubject.path.split("{")[0]
                      }{"sessionCode":"${UserLastSession.katSessionCode}",${
                        UserSubject.path.split("{")[1]
                      }{"_id":"${UserLastSession.katUnitID}","username":"${
                        userCredsvalues.user.userName
                      }","code":"${userCredsvalues.user.password}"}}/1/${
                        UserSubject.bookId
                      }/1/1`
                    );
                  } else {
                    window.open(
                      `https://iskconsamskriti.katbook.com/#/kampus/BookView/${
                        UserSubject.path.split('"parentVaribaleId"')[0]
                      }"parentVaribaleId":{"username":"${
                        userCredsvalues.user.userName
                      }","code":"${userCredsvalues.user.password}"}}/1/${
                        UserSubject.bookId
                      }/1/1`
                    );
                  }

                  //   window.close();
                }}
                onTouchStart={() => {
                  if (UserLastSession) {
                    window.open(
                      `https://iskconsamskriti.katbook.com/#/kampus/BookView/${
                        UserSubject.path.split("{")[0]
                      }{"sessionCode":"${UserLastSession.katSessionCode}",${
                        UserSubject.path.split("{")[1]
                      }{"_id":"${UserLastSession.katUnitID}","username":"${
                        userCredsvalues.user.userName
                      }","code":"${userCredsvalues.user.password}"}}/1/${
                        UserSubject.bookId
                      }/1/1`
                    );
                  } else {
                    window.open(
                      `https://iskconsamskriti.katbook.com/#/kampus/BookView/${
                        UserSubject.path.split('"parentVaribaleId"')[0]
                      }"parentVaribaleId":{"username":"${
                        userCredsvalues.user.userName
                      }","code":"${userCredsvalues.user.password}"}}/1/${
                        UserSubject.bookId
                      }/1/1`
                    );
                  }

                  //   window.close();
                }}
                className={"pickupBtn"}
              >
                <span className={"pickupTxt"}>
                  Pick up where you left off :{" "}
                  {UserLastSession
                    ? moment(UserLastSession.date).format("MMM DD, YYYY")
                    : moment().format("MMM DD, YYYY")}
                </span>
              </div>
              <div
                onClick={() => {
                  if (UserLastSession) {
                    window.open(
                      `https://iskconsamskriti.katbook.com/#/kampus/BookView/${
                        UserSubject.path.split("{")[0]
                      }{"sessionCode":"${UserLastSession.katSessionCode}",${
                        UserSubject.path.split("{")[1]
                      }{"_id":"${UserLastSession.katUnitID}","username":"${
                        userCredsvalues.user.userName
                      }","code":"${userCredsvalues.user.password}"}}/1/${
                        UserSubject.bookId
                      }/1/1`
                    );
                  } else {
                    window.open(
                      `https://iskconsamskriti.katbook.com/#/kampus/BookView/${
                        UserSubject.path.split('"parentVaribaleId"')[0]
                      }"parentVaribaleId":{"username":"${
                        userCredsvalues.user.userName
                      }","code":"${userCredsvalues.user.password}"}}/1/${
                        UserSubject.bookId
                      }/1/1`
                    );
                  }

                  //   window.close();
                }}
                onTouchStart={() => {
                  if (UserLastSession) {
                    window.open(
                      `https://iskconsamskriti.katbook.com/#/kampus/BookView/${
                        UserSubject.path.split("{")[0]
                      }{"sessionCode":"${UserLastSession.katSessionCode}",${
                        UserSubject.path.split("{")[1]
                      }{"_id":"${UserLastSession.katUnitID}","username":"${
                        userCredsvalues.user.userName
                      }","code":"${userCredsvalues.user.password}"}}/1/${
                        UserSubject.bookId
                      }/1/1`
                    );
                  } else {
                    window.open(
                      `https://iskconsamskriti.katbook.com/#/kampus/BookView/${
                        UserSubject.path.split('"parentVaribaleId"')[0]
                      }"parentVaribaleId":{"username":"${
                        userCredsvalues.user.userName
                      }","code":"${userCredsvalues.user.password}"}}/1/${
                        UserSubject.bookId
                      }/1/1`
                    );
                  }

                  //   window.close();
                }}
                className={"katL"}
              >
                <img src={KatbookLogo} />
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div
              className="Sd_Video Sd_Video_Button"
              // className={`Sd_Video Sd_Video_Button ${
              //   isBigScreen ? "w-100" : "col-md-4"
              // }`}
              style={{ borderRadius: "15px" }}
            >
              <Player
                fluid={isTabScreen ? true : isBigScreen ? false : true}
                playsInline
                height={403}
                src="https://s3.ap-south-1.amazonaws.com/katbook1.0/seb/demo_video.mp4"
              />
            </div>
          </div>

          <div className="col-md-3">
            <div
              className={`Coverage_report_Color dateDis ${
                isBigScreen ? "py-0" : "py-4"
              } `}
            >
              <div>
                <span className="Y_Katbook_Cov flash">
                  Exam Date : 22nd JAN, 2023
                </span>
              </div>
            </div>
            <div
              className={`Coverage_report_Color ${
                isBigScreen ? "py-0" : "py-4"
              } `}
            >
              <div className={"marginTop-20"}>
                <span className="Y_Katbook_Cov">Your KATBook Coverage</span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                  lineHeight: "18px",
                }}
              >
                <img src={StLogo} className="Name_Image" />
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    width: "90px",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  {localStorage.length > 0 &&
                    JSON.parse(localStorage.userCreds).user.fullName}
                </span>
              </div>

              <div className="Flex_D_Percent marginBottom-20">
                <div className="Flex_Gap">
                  <div className="PercentComplete">
                    <p className="Percent-Font">
                      {Progresssessioncount && Booksessioncount
                        ? Math.floor(
                            (Progresssessioncount / Booksessioncount) * 100
                          )
                        : 0}
                      %
                    </p>
                  </div>
                  <div className="Line_Height">
                    <span className="L_C_Font">Lesson Coverage</span>
                  </div>
                </div>

                <div className="Flex_Gap">
                  <div className="PercentComplete">
                    <p className="Percent-Font">
                      {Progressquestioncount && Bookquestioncount
                        ? Math.floor(
                            (Progressquestioncount / Bookquestioncount) * 100
                          )
                        : 0}
                      %
                    </p>
                  </div>
                  <div className="Line_Height">
                    <span className="L_C_Font">Assessment Coverage</span>
                  </div>
                </div>

                <div className="Flex_Gap">
                  <div className="PercentComplete">
                    <p className="Percent-Font">
                      {Progresssessioncount &&
                      Booksessioncount &&
                      Progressquestioncount &&
                      Bookquestioncount
                        ? Math.floor(
                            (Math.floor(
                              (Progresssessioncount / Booksessioncount) * 100
                            ) +
                              Math.floor(
                                (Progressquestioncount / Bookquestioncount) *
                                  100
                              )) /
                              2
                          )
                        : 0}
                      %
                    </p>
                  </div>
                  <div className="Line_Height">
                    <span className="L_C_Font">Overall</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div style={{marginTop:"30px"}}>
        <div style={{display:"flex",justifyContent:"center"}}>
          <h4>
            Student's Progress
          </h4>
        </div>

        <div style={{display:"flex",justifyContent:"center",marginTop:"30px",textAlign:"center",alignItems:"center"}}>
          <div style={{position:"relative",width:300}}>
          <BiSearch style={{position:"absolute",left:70,bottom:"25%",color:"white"}} />
          <input type="search" placeholder="Search Students"
          style={{
            paddingLeft : 30,
            borderRadius: "5px",
            width: "180px",
            height: "38px",
            textAlign: "center",
            border: "none",
            backgroundColor: "black",
            color: "white"
          }}
          />
          </div>
          
          <div>
            <BsListCheck   onClick={()=>{setListView(!listView)}}
            className="ListIcon" />
          </div>
        </div>
      </div> */}

      {/* { listView && (
          <div style={{
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            paddingLeft:"550px",
            }}>
             
            <div className="py-2 pb-1 ButtonDiv">

              { data.ButtonYears.map((x)=>{
                return (           
                  <div className="pb-2">  
                    <button className="Y_Button">
                      {x.S_Year}
                    </button>  
                  </div>           
                )
              })} 
            </div>             
           </div>

          )} */}

      {/* <div className="py-3">

      { data.StudentSearch.map((x)=>{
        return (
          <>
        <div className="py-4">
          <div className="col-md-11 d-flex pr-5" style={{ justifyContent: "right"}}>
              <button className="ViewMoreDetails">
                View more&nbsp;<BiChevronRight size="22" />
              </button>
          </div>
      
        <div className="d-flex flex-column justify-content-center align-items-center">        

            <div className="col-md-10 ">        
                   
              <div className="col-md-5 CardStudentDetails pl-0">             
                
                <div className="StudentYear"> 
                  {x.Year}
                </div>

                <div style={{display:"flex"}}>

                <div style={{
                  display:"flex",
                  flexDirection:"column",
                  paddingTop:"15px",
                  paddingLeft:"20px",
                  gap:"15px",
                  textAlign:"center",
                  alignItems: "center"
                  }}>
                  <div
                    // style={{
                    // width :"50px",
                    // height : "50px",                    
                    // }}
                  >
                    <img 
                      style={{
                        width :"100%",
                        height : "100%",
                        borderRadius :"50%"
                      }}
                    src={x?.Img1 ? x?.Img1 === "" ? StLogo : x?.Img1 : StLogo} alt="" />
                  </div>

                  <div >
                    <p style={{
                      fontSize:"15px",
                      fontWeight:"500",
                      width:"80px"
                      }}>
                        {x.Name1}
                    </p>
                  </div>
                </div>

                <div className="pl-5 pt-0">
                <div style={{display:"flex",flexDirection:"column",gap:"10px",marginTop:"-16px"}}>
    
                  <div className="PercentComplete"> 
                    <p style={{fontSize:"22px",fontWeight:"600"}}>
                      {x.Percent1}
                    </p>
                  </div>

                  <div>
                    <p style={{fontSize:"15px",fontWeight:"500",width:"80px",textAlign:"center"}}>
                      {x.L_Coverage}
                    </p>
                  </div>
                </div>             
                </div>


                <div className="pl-5 pt-0">
                <div style={{display:"flex",flexDirection:"column",gap:"10px",marginTop:"-16px"}}>
    
                  <div className="PercentComplete"> 
                    <p style={{fontSize:"22px",fontWeight:"600"}}>
                      {x.Percent2}
                    </p>
                  </div>

                  <div>
                    <p style={{fontSize:"15px",fontWeight:"500",width:"80px",textAlign:"center"}}>
                      {x.A_Coverage}
                    </p>
                  </div>
                </div>             
                </div>


                <div className="pl-5 pt-0">
                <div style={{display:"flex",flexDirection:"column",gap:"10px",marginTop:"-16px",alignItems:"center"}}>
    
                  <div className="PercentComplete"> 
                    <p style={{fontSize:"22px",fontWeight:"600"}}>
                      {x.Percent3}
                    </p>
                  </div>

                  <div>
                    <span style={{fontSize:"15px",fontWeight:"500",width:"80px",textAlign:"center"}}>
                      {x.All}
                    </span>
                  </div>
                </div>             
                </div>

                </div>
              </div>

              <div className="col-md-5 CardStudentDetails">
              
                <div style={{display:"flex",marginTop:"30px"}}>

                <div style={{
                  display:"flex",
                  flexDirection:"column",
                  paddingTop:"13px",
                  paddingLeft:"20px",
                  gap:"15px",
                  textAlign:"center",
                  alignItems:"center"
                  }}>

                 <div
                    // style={{
                    // width :"50px",
                    // height : "50px",                    
                    // }}
                  >
                    <img 
                      style={{
                        width :"100%",
                        height : "100%",
                        borderRadius :"50%"
                      }}
                    src={x?.Img1 ? x?.Img1 === "" ? StLogo : x?.Img1 : StLogo} alt="" />
                  </div>

                  <div >
                    <p style={{
                      fontSize:"15px",
                      fontWeight:"500",
                      width:"80px"
                      }}>
                        {x.Name2}
                    </p>
                  </div>
                </div>

                <div className="pl-4 pt-0">
                <div style={{display:"flex",flexDirection:"column",gap:"10px",marginTop:"-16px"}}>

                  <div className="PercentComplete"> 
                    <p style={{fontSize:"22px",fontWeight:"600"}}>
                      {x.Percent4}
                      </p>
                  </div>

                  <div>
                    <p style={{fontSize:"15px",fontWeight:"500",width:"80px",textAlign:"center"}}>
                      {x.L_Coverage}
                    </p>
                  </div>
                </div>             
                </div>


                <div className="pl-5 pt-0">
                <div style={{display:"flex",flexDirection:"column",gap:"10px",marginTop:"-16px"}}>

                  <div className="PercentComplete"> 
                    <p style={{fontSize:"22px",fontWeight:"600"}}>
                      {x.Percent5}
                    </p>
                  </div>

                  <div>
                    <p style={{fontSize:"15px",fontWeight:"500",width:"80px",textAlign:"center"}}>
                      {x.A_Coverage}
                    </p>
                  </div>
                </div>             
                </div>


                <div className="pl-5 pt-0">
                <div style={{display:"flex",flexDirection:"column",gap:"10px",marginTop:"-16px",alignItems:"center"}}>

                  <div className="PercentComplete"> 
                    <p style={{fontSize:"22px",fontWeight:"600"}}>
                      {x.Percent6}
                    </p>
                  </div>

                  <div>
                    <span style={{fontSize:"15px",fontWeight:"500",width:"80px",textAlign:"center"}}>
                      {x.All}
                    </span>
                  </div>
                </div>             
                </div>

                </div>


              </div>

              <div className="col-md-2 CardStudentDetails">

                <div style={{display:"flex",marginTop:"30px"}}>

                <div style={{
                display:"flex",
                flexDirection:"column",
                paddingTop:"15px",
                paddingLeft:"0px",
                gap:"15px",
                textAlign:"center",
                alignItems:"center"
                }}>

               <div
                    // style={{
                    // width :"50px",
                    // height : "50px",                    
                    // }}
                  >
                    <img 
                      style={{
                        width :"100%",
                        height : "100%",
                        borderRadius :"50%"
                      }}
                    src={x?.Img1 ? x?.Img1 === "" ? StLogo : x?.Img1 : StLogo} alt="" />
                  </div>

                <div >
                  <p style={{
                    fontSize:"15px",
                    fontWeight:"500",
                    width:"80px"
                    }}>
                      {x.Name3}
                  </p>
                </div>
                </div>

                <div className="pl-3 pt-0">
                <div style={{display:"flex",flexDirection:"column",gap:"10px",marginTop:"-16px"}}>

                <div className="PercentComplete"> 
                  <p style={{fontSize:"22px",fontWeight:"600"}}>
                    {x.Percent7}
                  </p>
                </div>

                <div>
                  <p style={{fontSize:"15px",fontWeight:"500",width:"80px",textAlign:"center"}}>
                    {x.L_Coverage}
                  </p>
                </div>
                </div>             
                </div>


                </div>

              </div>

            </div>
      </div>
      </div>
      </>             
      )
      })}
      </div>    */}
    </div>
  );
};

export default Dashboard;
