import React from "react";
import handimage from "../../Assets/imgehand.png";
import AppleLogo from "../../Assets/apple.png";
import GoogleLogo from "../../Assets/google-play (1).png";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker/useAnalyticsEventTracker";

function Download() {
  const ANDROID_LINK = "https://tinyurl.com/bdf4afuz";
  const APPLE_LINK = "https://appho.st/d/5P100kVA/v/1.0";
  const gaEventTracker = useAnalyticsEventTracker("DownloadApp");
  return (
    <div className="d-flex flex-column flex-md-row w-100 h-screen container p-5">
      <div className="d-flex flex-column justify-content-center align-items-center ">
        <div className="d-flex flex-column w-100 h-100 pt-5">
          <div className="h2">Download This App</div>
          <div className="d-flex flex-column pt-3">
            <div className="pb-2">Download This ISKCON Samskriti App</div>
            <div className="p">
              Enjoy the Way of Learning Online through KAMPUS and KATBook
            </div>
            {/* <div
              className="p"
              onClick={() => {
                gaEventTracker("Apple");
                window.open(APPLE_LINK, "_blank");
              }}
            >
              <div
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#000000",
                }}
                className="d-flex flex-row align-items-center w-100 apple-border py-2"
              >
                <div
                  style={{
                    height: "60px",
                  }}
                  className="pl-4"
                >
                  <img
                    src={AppleLogo}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div className="pl-2 pt-3">
                  <div style={{ color: "#ffffff" }} className="h6 pb-0 mb-0">
                    Download On the
                  </div>
                  <div style={{ color: "#ffffff" }} className="h4">
                    App Store
                  </div>
                </div>
              </div>
            </div> */}
            <div className="p">
              <div
                onClick={() => {
                  gaEventTracker("Android");
                  window.open(ANDROID_LINK);
                }}
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#000000",
                }}
                className="d-flex flex-row align-items-center w-100 apple-border py-2"
              >
                <div
                  className="pl-4"
                  style={{
                    height: "60px",
                  }}
                >
                  <img
                    src={GoogleLogo}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div className="pl-2 pt-3">
                  <div style={{ color: "#ffffff" }} className="h6 pb-0 mb-0">
                    Get it On
                  </div>
                  <div style={{ color: "#ffffff" }} className="h4">
                    Google Play Store
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex pl-3 flex-column justify-content-center align-items-center w-100 h-100 pt-5">
        <img
          src={handimage}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </div>
    </div>
  );
}

export default Download;
