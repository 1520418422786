import React from "react";
import cake from "../../../Assets/Birthday cake-rafiki 1.png";
// import firpic from "../../../Assets/student_pic.png";
import secpic from "../../../Assets/Mask Group.png";

function Birthday() {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const current = new Date();
    const date = ` ${current.getDate()} ${months[current.getMonth()]}`;

    // var imgArray = new Array();
    // imgArray[0] = new Image();
    // imgArray[0].src = '../../../Assets/student_pic.png';
    const birthDayArray= [
      {Image: "https://thumbs.dreamstime.com/z/man-profile-cartoon-smiling-vector-illustration-graphic-design-135443492.jpg", Title:"Jai Balaji", Class: "Class XI-A"},
      {Image: "https://images.statusfacebook.com/profile_pictures/animation/animation_profile_pictures_21.jpg", Title: "Manju", Class: "Class VI-B"},
    ]    
    
    const UpComingArray= [
      { Image: "https://308286-943399-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2022/04/girl-attitude-stylish-whatsapp-dp-cartoon.jpeg", Title: "Keerthi", class: "Class VI-B" , date: "15 Aug"},
      { Image: "https://308286-943399-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2022/04/girl-attitude-stylish-whatsapp-dp-cartoon.jpeg", Title: "Akshaya", class: "Class VI-B" , date: "25 Aug"},
      // { Image: "https://308286-943399-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2022/04/girl-attitude-stylish-whatsapp-dp-cartoon.jpeg", Title: "Vandhana", class: "Class VI-B" , date: "01 Sep"},
      // { Image: "https://308286-943399-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2022/04/girl-attitude-stylish-whatsapp-dp-cartoon.jpeg", Title: "Nivetha", class: "Class VI-B" , date: "07 Sep"},
      // { Image: "https://308286-943399-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2022/04/girl-attitude-stylish-whatsapp-dp-cartoon.jpeg", Title: "Abinaya", class: "Class VI-B" , date: "14 Sep"},
      // { Image: "https://308286-943399-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2022/04/girl-attitude-stylish-whatsapp-dp-cartoon.jpeg", Title: "Saranya", class: "Class VI-B" , date: "20 Sep"},
      // { Image: "https://308286-943399-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2022/04/girl-attitude-stylish-whatsapp-dp-cartoon.jpeg", Title: "Thara", class: "Class VI-B" , date: "25 Sep"},
    ]
    
  return (
    <div>
      <h5 className={"Time_Table_BirthDay"}>Birthday</h5>
      <h5 className={"birthTitle"}>Birthday</h5>

      <div className="birthCard">
        
        <div className="d-flex flex-row justify-content-between">
          <div>
            <h3 className={"Textbirth"}>Happy Birthday</h3>
          </div>

          <div>
            <img src={cake} alt="" className="imgbirthCake" />
          </div>
        </div>

        {
                birthDayArray.map( (item) => 
                <div>
        <div className="d-flex flex-row justify-content-between py-3 border-bottom">
          <div className="gapimh">
            <div>
              <img src={item.Image} className="ipmics_img"/>
            </div>

            <div className="d-flex flex-column">
              <div className="imhtext">{item.Title}</div>
              <div className="imhtext2">{item.Class}</div>
            </div>
          </div>

          <div className="wishbith">
            <span className="senwishText">Send Wishes</span>
            </div>
        </div>        
        </div>
        )}

    <div className="ovescol">
          <h5 className="upcomeBirth mt-3">Upcoming</h5>

          {
                UpComingArray.map( (item, i) => 
        <div className="d-flex flex-row justify-content-between py-3 border-bottom">
          <div className="gapimh">
            <div>
              <img src={item.Image} className="ipmics_img"/>
            </div>

            <div className="d-flex flex-column">
              <div className="imhtext">{item.Title}</div>
              <div className="imhtext2">{item.class}</div>
            </div>
          </div>

          <div className="wishbith2">{item.date}</div>
        </div>

)
}

        

      </div>
      </div>
    </div>
  );
}

export default Birthday;
